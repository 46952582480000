import { useEffect, useState } from 'react'
import { Avatar, Grid, MenuItem, Paper, TextField, Typography, DialogActions, Button, Card } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import apiCNP from '../../services/apiCNP'
import { useDispatch, useSelector } from 'react-redux';
import LoadingBackdrop from '../../load/loadingBackdrop';
import MessageError from '../../messages/messageError';
import cadastrosService from '../../services/cadastroServices';
import { useNavigate } from 'react-router-dom';
import RegisterAccess from '../../utils/registerAccess';

const useStyles = makeStyles((theme) => ({
    root: { alignItems: 'end' },

    gridItem: { padding: 5 }

}))

export default function CadastroRepresentante({ cadastro }) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const dadosRepresentante = useSelector(state => state.dadosRepresentante.data)
    const navigate = useNavigate()
    const [representante, setRepresentante] = useState(dadosRepresentante)
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [errorMessage, setErrorMessage] = useState('')
    const user = useSelector(state => state.account.user);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        dispatch({
            type: 'dadosRepresentante',
            payload: { dadosRepresentante: representante }
        })
    }, [representante, dispatch])

    useEffect(() => {
        apiCNP.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);
            }).catch(err => '')

        dadosRepresentante?.provinciaId &&
            apiCNP.get(`/municipios-by-provincia/${dadosRepresentante.provinciaId}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                }).catch(err => '')
    }, [dadosRepresentante?.provinciaId])


    async function HandleChange(e) {

        e.target.files ?
            setRepresentante({ ...representante, [e.target.name]: e.target.files[0] })
            :
            setRepresentante({ ...representante, [e.target.name]: e.target.value })

        e.target.name === 'provinciaId' &&
            await apiCNP.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);

                    setRepresentante((prevCadastro) => ({ ...prevCadastro, provincia: res.data.provincia.designacao }));
                }).catch(err => '')
    }

    async function createRepresentante() {
        setOpen(true)

        try {
            const result = await cadastrosService.cadastroRepresentante(dadosRepresentante, user)
            setOpen(false)

            navigate(`/admin/get-representante/${result.data.representante._id}`)
            dispatch({
                type: 'alert',
                payload: { alert: 'Representante cadastrado com sucesso.' },
            });

            return result;
        } catch (error) {

            // console.log(error)
            setErrorMessage(error.response.data.message)
            setOpen(false)

            // throw error;
        }
    }

    return (
        <>
            <RegisterAccess page={'cadastro de representante'} />
            {errorMessage && <MessageError message={errorMessage} />}

            <LoadingBackdrop open={open} text={'A cadastrar Representante'} />

            <Card style={{ padding: 20, boxShadow: 'none' }}>
                <Typography style={{ fontSize: 20, marginBottom: 10 }}>
                    DADOS DO REPRESENTANTE
                </Typography>

                <Grid container >
                    <Grid xs={12} md={2} item className={classes.gridItem} >
                        <Paper style={{ border: '1px dashed #3e3d3f' }}>
                            <label htmlFor="foto" style={{ cursor: 'pointer', textAlign: 'center' }}>
                                {representante?.foto ?
                                    <>
                                        {/* <img src={URL.createObjectURL(representante.foto)} alt="Imagem" width="100%" title="alterar foto.." /> */}
                                        <Avatar style={{ width: '100%', height: 145 }} variant="square" src={URL.createObjectURL(representante.foto)} title="Clique para carregar uma imagem" />
                                    </>
                                    :
                                    <div style={{ padding: 7 }}>
                                        <img alt='...' style={{ height: 100 }} src='/img/avatar/avatarRepresentante.svg' title="Clique para carregar uma imagem" />
                                        <p>Foto</p>
                                    </div>
                                }
                                <input accept="image/png, image/jpg, image/jpeg" type="file" name="foto" id="foto" style={{ display: 'none' }} onChange={HandleChange} />
                            </label>
                        </Paper>
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="text"
                            required
                            label="Nome"
                            fullWidth
                            size="small"
                            name="nome"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={representante?.nome}
                        />

                        <TextField
                            style={{ marginTop: 15 }}
                            type="text"
                            required
                            label="Número de B.I"
                            fullWidth
                            size="small"
                            name="numeroBI"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={representante?.numeroBI}
                        />

                        <TextField
                            style={{ marginTop: 15 }}
                            type="text"
                            label="Genero"
                            select
                            fullWidth
                            size="small"
                            name="genero"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={representante?.genero ?? ''}
                        >
                            <MenuItem disabled value=''>Selecione Genero</MenuItem>
                            <MenuItem key={'M'} value={'M'} name="genero">
                                Masculino
                            </MenuItem>
                            <MenuItem key={'F'} value={'F'} name="genero">
                                Feminino
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="number"
                            required
                            label="Telefone1"
                            fullWidth
                            size="small"
                            name="tel1"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={representante?.tel1}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="number"
                            label="Telefone2"
                            fullWidth
                            size="small"
                            name="tel2"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={representante?.tel2}
                        />
                    </Grid>
                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="email"
                            required
                            label="Email"
                            fullWidth
                            size="small"
                            name="email"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={representante?.email}
                        />
                    </Grid>
                </Grid>

                <Grid container className={classes.root}>
                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Provincia"
                            select
                            fullWidth
                            size="small"
                            name="provinciaId"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={representante?.provinciaId ?? ''}
                        >
                            <MenuItem disabled value=''>Selecione Províncía</MenuItem>
                            {provincias?.map((provincia) => (
                                <MenuItem key={provincia._id} value={provincia._id}>
                                    {provincia.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        {representante?.provinciaId ?
                            <TextField
                                required
                                type="text"
                                label="Municipio"
                                select
                                fullWidth
                                size="small"
                                name="municipio"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={representante?.municipio ?? ''}
                            >
                                <MenuItem disabled value=''>Selecione Municipio</MenuItem>
                                {municipios?.map((municipio) => (
                                    <MenuItem key={municipio._id} value={municipio.designacao}>
                                        {municipio.designacao}
                                    </MenuItem>
                                ))}
                            </TextField>
                            :
                            <TextField
                                type="text"
                                disabled
                                label="Municipio"
                                fullWidth
                                size="small"
                                variant="outlined"
                            />
                        }
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Bairro"
                            fullWidth
                            size="small"
                            name="bairro"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={representante?.bairro}
                        />
                    </Grid>
                </Grid>

                <Grid xs={12} md={4} item className={classes.gridItem}>
                    <TextField
                        required
                        type="text"
                        label="Rua"
                        fullWidth
                        size="small"
                        name="rua"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={representante?.rua}
                    />
                </Grid>

                {/* A VARIAVEL CADASTRO INDICA DO QUE O REPRESENTANTE ESTA A SER CADASTRADO DE FORMA INDIVIDUAL */}
                {cadastro &&
                    <DialogActions>
                        <Button disabled={!representante?.nome} variant="contained" style={{ background: '#85287e' }} onClick={createRepresentante}>
                            Cadastrar
                        </Button>
                    </DialogActions>
                }
            </Card>
        </>
    )
}
