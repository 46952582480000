import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import FichaDirectorTecnico from "./fichaDirectorTecnico";
import EstabelecimentosAssociados from "../gestEmpresas/estabelecimentosAssociados";
import HistoricoActividadesByModel from "../departamentos/dlif/licenciamento/historicoActividadesByModel.jsx";
import HeaderSession from "../../utils/headerSession";

export default function GetDirectorTecnico() {
    const { id } = useParams()
    const [directorTecnico, setDirectorTecnico] = useState('')
    const [refresh, setRefresh] = useState('')

    useEffect(() => {
        api.get(`/director-tecnico/${id}`)
            .then(response => {
                // console.log(response)
                setDirectorTecnico(response.data.directorTecnico);
            }).catch(err => '')
    }, [id, refresh])

    return (
        <>
            <HeaderSession title={'GESTÃO DE DIRECTOR TÉCNICO'} />

            <FichaDirectorTecnico directorTecnico={directorTecnico} header={true} setRefresh={setRefresh}/>

            {directorTecnico &&
                <EstabelecimentosAssociados parametro={'directorTecnicoId'} />
            }

            <HistoricoActividadesByModel model={directorTecnico}/>
        </>
    )
}