import React, { useEffect } from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PrintIcon from '@mui/icons-material/Print';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import { Divider, List } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


export default function NavLateralDlif({ classes }) {
    const { id } = useParams()
    const url = useLocation();
    const { provincia } = useParams()
    const user = useSelector(state => state.account.user);
    const navegate = useNavigate()

    const [openEstabelecimento, setOpenEstabelecimento] = React.useState(false);
    const [openEmpresa, setOpenEmpresa] = React.useState(false);
    const [openRepresentante, setOpenRepresentante] = React.useState(false);
    const [openDT, setOpenDT] = React.useState(false);
    const [openAgenda, setOpenAgenda] = React.useState(false);
    const [openGestAdmin, setOPenGestAdmin] = React.useState(false);
    const [openCentralImpressao, setOPenCentralImpressao] = React.useState(false);
    const [openGestSite, setOpenGestSite] = React.useState(false);

    const selectOpen = (event) => {
        setOpenEstabelecimento(event === 'estabelecimento' && !openEstabelecimento);
        setOpenEmpresa(event === 'empresa' && !openEmpresa)
        setOpenRepresentante(event === 'representante' && !openRepresentante)
        setOpenDT(event === 'DT' && !openDT)
        setOpenAgenda(event === 'agenda' && !openAgenda)
        setOPenGestAdmin(event === 'gestAdmin' && !openGestAdmin)
        setOPenCentralImpressao(event === 'centralImpressao' && !openCentralImpressao)
        setOpenGestSite(event === 'getSite' && !openGestSite)
    };

    // useEffect(() => {
    //     if (user.departamento === 'DLIF' || user?.funcao === 'Director Geral' || user?.funcao === 'CNP') {
    //         console.log('entrou')
    //     } else {navegate(-1) }

    // }, [user, url])

    return (
        <>
            <List>

                <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/dashboard' || url.pathname === `/admin/provincia/${provincia}`) && classes.ondeEstou}`}>
                    <ListItemButton onClick={() => { navegate('/admin/dashboard') }}>
                        <img src="/icones/Dashboard.svg" width={30} style={{ marginRight: 15 }} alt="" />
                        Dashboard
                    </ListItemButton>
                </div>
                <div className={`${classes.hoverListItem} ${url.pathname === '/admin/dlif' && classes.ondeEstou}`}>
                    <ListItemButton onClick={() => { navegate('/admin/dlif') }}>
                        <img src="/icones/Menu.svg" width={30} style={{ marginRight: 15 }} alt="" />
                        Menu
                    </ListItemButton>
                </div>

                {(user?.funcao === 'CNP' || user?.funcao === 'Chefe de Departamento' || user?.funcao === 'Director Geral') &&
                    <div className={`${classes.hoverListItem} ${url.pathname === '/admin/dlif/relatorios' && classes.ondeEstou}`}>
                        {/* <MenuItem size='small' onClick={() => navegate('/admin/dlif/relatorios')}> <ListItemIcon> </ListItemIcon> Relatórios </MenuItem> */}
                        <ListItemButton onClick={() => { navegate('/admin/dlif/relatorios') }}>
                            <img src="/icones/relatorio.svg" width={35} style={{ marginRight: 15 }} alt="" />
                            Relatórios
                        </ListItemButton>
                    </div>
                }

                <br />
                <Divider style={{ background: '#c43ab9' }} />
                <br />

                <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/gestor-estabelecimentos-licenciados' || url.pathname === `/admin/gestor-licenciados/${id}` || url.pathname === `/admin/gestor-licenciados/inspecao/${id}` || url.pathname === `/admin/gestor-licenciados/observacoes/${id}` || url.pathname === `/admin/gestor-licenciados/responsavel-pelo-registo/${id}`) && classes.ondeEstou}`}>
                    <ListItemButton onClick={() => selectOpen('estabelecimento')}>
                        <img src="/icones/Estabelecimentos.svg" width={35} style={{ marginRight: 15 }} alt="" />
                        <ListItemText disableTypography primary="Estabelecimentos" />
                        {/* <Badge style={{ padding: 7 }} badgeContent={estabelecimentos}
                                        color="error"
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}>
                                    </Badge> */}
                        {openEstabelecimento ? <ExpandMore /> : <NavigateNext />}
                    </ListItemButton>
                </div>
                <Collapse in={openEstabelecimento} timeout="auto" unmountOnExit>
                    <List component="div" style={{ background: '#24274a' }} >
                        <ListItemButton onClick={() => navegate('/admin/gestor-estabelecimentos-licenciados')}>
                            Ver Estabelecimentos Licenciados
                        </ListItemButton>
                        <ListItemButton onClick={() => navegate('/admin/categorias-de-estabelecimento')}>
                            Categorias de Estabelecimentos
                        </ListItemButton>
                        <ListItemButton onClick={() => navegate('/admin/cadastro-de-estabelecimento')}>
                            Cadastrar Estabelecimentos
                        </ListItemButton>
                    </List>
                </Collapse>


                <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/gestor-empresas' || url.pathname === '/admin/cadastro-empresa') && classes.ondeEstou}`}>
                    <ListItemButton onClick={() => selectOpen('empresa')}>
                        <img src="/icones/Empresa.svg" width={35} style={{ marginRight: 15 }} alt="" />
                        <ListItemText disableTypography primary="Empresas" />
                        {openEmpresa ? <ExpandMore /> : <NavigateNext />}
                    </ListItemButton>
                </div>
                <Collapse in={openEmpresa} timeout="auto" unmountOnExit>
                    <List component="div" style={{ background: '#24274a' }} >
                        <ListItemButton onClick={() => navegate('/admin/gestor-empresas')}>
                            Ver Empresas
                        </ListItemButton>
                        <ListItemButton onClick={() => navegate('/admin/cadastro-empresa')}>
                            Cadastrar Empresa
                        </ListItemButton>
                    </List>
                </Collapse>



                <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/gestor-representantes' || url.pathname === '/admin/cadastro-representante') && classes.ondeEstou}`}>
                    <ListItemButton onClick={() => selectOpen('representante')}>
                        <img src="/icones/Representante.svg" width={35} style={{ marginRight: 15 }} alt="" />
                        <ListItemText disableTypography primary="Representantes" />
                        {openRepresentante ? <ExpandMore /> : <NavigateNext />}
                    </ListItemButton>
                </div>
                <Collapse in={openRepresentante} timeout="auto" unmountOnExit>
                    <List component="div" style={{ background: '#24274a' }} >
                        <ListItemButton onClick={() => navegate('/admin/gestor-representantes')}>
                            Ver Representantes
                        </ListItemButton>
                        <ListItemButton onClick={() => navegate('/admin/cadastro-representante')}>
                            Cadastrar Representante
                        </ListItemButton>
                        <ListItemButton disabled>
                            Comunicados
                        </ListItemButton>
                    </List>
                </Collapse>


                <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/gestor-director-tecnicos' || url.pathname === '/admin/cadastro-director-tecnico' || url.pathname === '/admin/categorias-director-tecnico') && classes.ondeEstou}`}>
                    <ListItemButton onClick={() => selectOpen('DT')}>
                        <img src="/icones/Director_tecnico.svg" width={35} style={{ marginRight: 15 }} alt="" />
                        <ListItemText disableTypography primary="Directores Técnicos" />
                        {openDT ? <ExpandMore /> : <NavigateNext />}
                    </ListItemButton>
                </div>
                <Collapse in={openDT} timeout="auto" unmountOnExit>
                    <List component="div" style={{ background: '#24274a' }} >
                        <ListItemButton onClick={() => navegate('/admin/gestor-director-tecnicos')}>
                            Ver Directores Técnicos
                        </ListItemButton>
                        <ListItemButton onClick={() => navegate('/admin/cadastro-director-tecnico')}>
                            Cadastrar Directores Técnicos
                        </ListItemButton>
                        <ListItemButton onClick={() => navegate('/admin/categorias-director-tecnico')}>
                            Categorias Directores Técnicos
                        </ListItemButton>
                        <ListItemButton disabled>
                            Comunicados
                        </ListItemButton>
                    </List>
                </Collapse>



                {user?.funcao === 'CNP' &&
                    <>
                        <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/central-impressao/qrcode') && classes.ondeEstou}`}>
                            <ListItemButton onClick={() => selectOpen('centralImpressao')}>
                                {/* <img src="/icones/Director_tecnico.svg" width={35} style={{ marginRight: 15 }} alt="" /> */}
                                <PrintIcon style={{ fontSize: 30, marginRight: 15 }} />
                                <ListItemText disableTypography primary="Central de impressão" />
                                {openCentralImpressao ? <ExpandMore /> : <NavigateNext />}
                            </ListItemButton>
                        </div>
                        <Collapse in={openCentralImpressao} timeout="auto" unmountOnExit>
                            <List component="div" style={{ background: '#24274a' }} >
                                <a style={{ textDecoration: 'none', color: '#fff' }} href="/admin/central-impressao/qrcode" target="_blank">
                                    {/* onClick={() => navegate('/admin/central-impressao/qrcode')} */}
                                    <ListItemButton >
                                        QRcode export
                                    </ListItemButton>
                                </a>
                            </List>
                        </Collapse>
                    </>
                }
            </List>

            <br />
            <Divider style={{ background: '#c43ab9' }} />
            <br />

            <List>

                <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/gestor-agenda' || url.pathname === '/admin/create-agenda' || url.pathname === '/admin/agendas-marcadas') && classes.ondeEstou}`}>
                    <ListItemButton onClick={() => selectOpen('agenda')}>
                        <img src="/icones/Agenda.svg" width={35} style={{ marginRight: 15 }} alt="" />
                        <ListItemText disableTypography primary="Agenda" />
                        {openAgenda ? <ExpandMore /> : <NavigateNext />}
                    </ListItemButton>
                </div>
                <Collapse in={openAgenda} timeout="auto" unmountOnExit>
                    <List component="div" style={{ background: '#24274a' }} >
                        <ListItemButton onClick={() => navegate('/admin/create-agenda')}>
                            Cria Agenda
                        </ListItemButton>
                        <ListItemButton onClick={() => navegate('/admin/gestor-agenda')}>
                            Agendas Disponíveis
                        </ListItemButton>
                        <ListItemButton onClick={() => navegate('/admin/agendas-marcadas')}>
                            Agendas Marcadas
                        </ListItemButton>
                    </List>
                </Collapse>

                <div className={classes.hoverListItem}>
                    <ListItemButton disabled onClick={() => { navegate('/admin/medicamentos') }}>
                        <img src="/icones/Medicamentos.svg" width={35} style={{ marginRight: 15 }} alt="" />
                        Medicamentos
                    </ListItemButton>
                </div>


                {/* <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/gestor-noticias') && classes.ondeEstou}`}>
                                <ListItemButton onClick={() => { navegate('/admin/gestor-noticias') }}>
                                    <img src="/icones/Noticias.svg" width={35} style={{ marginRight: 15 }} alt="" />
                                    Gestão de Notícias
                                </ListItemButton>
                            </div> */}

                <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/gestor-site' || url.pathname === '/admin/gestor-banner' || url.pathname === '/admin/gestor-noticias') && classes.ondeEstou}`}>
                    <ListItemButton onClick={() => selectOpen('getSite')}>
                        <img src="/icones/Noticias.svg" width={35} style={{ marginRight: 15 }} alt="" />
                        <ListItemText disableTypography primary="Gerenciador do site" />
                        {openGestSite ? <ExpandMore /> : <NavigateNext />}
                    </ListItemButton>
                </div>
                <Collapse in={openGestSite} timeout="auto" unmountOnExit>
                    <List component="div" style={{ background: '#24274a' }} >
                        {/* <ListItemButton onClick={() => navegate('/admin/gestor-site')}>
                                        Gestão de informações do site
                                    </ListItemButton> */}

                        <ListItemButton onClick={() => navegate('/admin/gestor-banner')}>
                            Gestão do Banner
                        </ListItemButton>
                        <ListItemButton onClick={() => { navegate('/admin/gestor-noticias') }}>
                            Gestão de Notícias
                        </ListItemButton>
                        <ListItemButton onClick={() => { navegate('/admin/gestor-docs') }}>
                            Gestão de Documentos
                        </ListItemButton>
                        <ListItemButton onClick={() => navegate('/admin/gestor-site')}>
                            Gestão de informações do site
                        </ListItemButton>

                    </List>
                </Collapse>


                {/* {(user?.dadosPessoais?.perfil === 'CNP' || user?.dadosPessoais?.perfil === 'Presidente') && */}
                <>

                    <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/lista-de-usuarios-administradores') && classes.ondeEstou}`}>
                        <ListItemButton onClick={() => selectOpen('gestAdmin')}>
                            <SettingsApplicationsOutlinedIcon style={{ fontSize: 30, marginRight: 15 }} />
                            <ListItemText disableTypography primary="Gerenciador de admin" />
                            {openGestAdmin ? <ExpandMore /> : <NavigateNext />}
                        </ListItemButton>
                    </div>
                    <Collapse in={openGestAdmin} timeout="auto" unmountOnExit>
                        <List component="div" style={{ background: '#24274a' }} >
                            <ListItemButton onClick={() => navegate('/admin/cadastro-administrador ')}>
                                Cadastrar administrador
                            </ListItemButton>
                            {(user?.funcao === 'CNP' || user?.funcao === 'Director Geral') &&
                                <>
                                    <ListItemButton onClick={() => navegate('/admin/lista-de-usuarios-administradores')}>
                                        Ver administradores
                                    </ListItemButton>
                                    <ListItemButton onClick={() => { navegate('/admin/historicos-de-actividades') }}>
                                        Historicos de actividades
                                    </ListItemButton>
                                </>
                            }
                        </List>
                    </Collapse>
                </>
                {/* } */}


                {/* <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/feedback') && classes.ondeEstou}`}>
                                <ListItemButton onClick={() => { navegate('/admin/feedback') }}>
                                    <FeedbackOutlinedIcon style={{ fontSize: 30, marginRight: 15 }} />
                                    <ListItemText disableTypography primary="Feedbacks" />
                                </ListItemButton>
                            </div> */}

                {user?.funcao === 'CNP' &&
                    <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/access-visity') && classes.ondeEstou}`}>
                        <ListItemButton onClick={() => { navegate('/admin/access-visity') }}>
                            <FindInPageOutlinedIcon style={{ fontSize: 30, marginRight: 15 }} />
                            <ListItemText disableTypography primary="Access" />
                        </ListItemButton>
                    </div>
                }

                <div className={`${classes.hoverListItem} ${(url.pathname === '/admin/manual-do-utilizador') && classes.ondeEstou}`}>
                    <ListItemButton disabled onClick={() => { navegate('/admin/manual-do-utilizador') }}>
                        {/* <img src="/icones/Noticias.svg" width={35} style={{ marginRight: 15 }} alt="" /> */}
                        <AutoStoriesIcon style={{ fontSize: 30, marginRight: 15 }} />
                        Manual do utilizador
                    </ListItemButton>
                </div>
            </List>
        </>
    )
}



