/// <reference no-default-lib="true"/>
/* eslint-disable */
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';
import LoadingBackdrop from "../../../load/loadingBackdrop";
import { useSelector } from 'react-redux';
import Editor from '../../../ckeditor/editor';
import api from '../../../services/api';
import ButtonLeft from '../../../utils/buttonLeft';
import FileUpload from '../../gestDocs/docUploads/fileUpload';
// import ImageInformacaoEdit from './imageInformacaoEdit';

const CreateInformacao = (props) => {
    const [informacao, setinformacao] = useState(props?.informacao)
    const [open, setOpen] = useState(false)
    const user = useSelector(state => state.account.user);

    const handleFileInput = (file, name) => {
        setinformacao((prevArquivos) => ({ ...prevArquivos, [name]: file }));
    };

    function HandleChange(e) {
        e.target.files ?
            setinformacao({ ...informacao, [e.target.name]: e.target.files[0] })
            :
            setinformacao({ ...informacao, [e.target.name]: e.target.value })
    }

    async function HandleCreateInformacao() {
        const formData = new FormData();
        formData.append('userId', user._id);
        formData.append('menu', informacao?.menu ?? '');
        formData.append('opcao', informacao?.opcao ?? '');
        formData.append('titulo', informacao?.titulo ?? '');
        formData.append('tipoFile', informacao?.tipoFile ?? '');
        formData.append('conteudo', informacao?.conteudo ?? '');
        formData.append('anexo', informacao?.anexo ?? '');

        setOpen(true)
        props.seterrorMessage('')
        props.setMessage('')

        props?.informacao ?
            // EDITANDO UMA informacao
            await api.put('/site-info/edit', { ...informacao, 'userId': user._id, 'siteInfoId': props.informacao._id })
                .then(res => {
                    setOpen(false)
                    // console.log(res)
                    props.setMessage(res.data.msg)
                    props.setCreateInformacao(false)
                }).catch(error => { 
                    props.seterrorMessage(error.response.data.message)
                    setOpen(false)
                })
            :
            // CRIANDO UMA NOVA informacao 
            await api.post('/site-info/create', formData)
                .then(res => {
                    setOpen(false)
                    // console.log(res)
                    props.setMessage(res.data.msg)
                    props.setCreateInformacao(false)
                }).catch(error => {

                    props.seterrorMessage(error.response.data.message)
                    setOpen(false)
                    // console.log(error)
                })
    }


    return (
        <>
            <LoadingBackdrop open={open} text={'A salvar. Aguarde!'} />

            <div>
                {/* <Typography align='center' variant="subtitle1" style={{ fontSize: 20 }}>
                    Editar informação
                </Typography>
                <br /> */}

                {/* COMPOMETE PARA FAZER UPLOADS DE DOCUMENTOS */}

                {!props?.informacao &&
                    <FileUpload maxSizeInMB={15} onFileSelect={(file) => handleFileInput(file, 'anexo')} inputName='anexo' inputTitle={'um anexo'} />
                }

                <br />
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Menu</InputLabel>
                    <Select
                        size='small'
                        labelId="menu"
                        id="menu"
                        name='menu'
                        defaultValue={informacao?.menu ?? ''}
                        label="Menu"
                        onChange={HandleChange}
                    >
                        <MenuItem value='INFORMAÇŌES'>
                            Informações
                        </MenuItem>
                        <MenuItem value='SOBRE NÓS'>
                            Sobre Nós
                        </MenuItem>
                        <MenuItem value='FARMACOVIGILÂNCIA'>
                            Farmacovigilância
                        </MenuItem>
                        <MenuItem value='LEGISLACÃO E DOCUMENTOS'>
                            Legislação e Documentos
                        </MenuItem>

                    </Select>
                </FormControl>

                {informacao?.menu === 'LEGISLACÃO E DOCUMENTOS' ?
                    <FormControl margin='dense' fullWidth>
                        <InputLabel id="demo-simple-select-label">Tipo de Documento </InputLabel>
                        <Select
                            size='small'
                            labelId="tipoFile"
                            id="tipoFile"
                            name='tipoFile'
                            defaultValue={informacao?.tipoFile ?? ''}
                            label="Tipo de Documento"
                            onChange={HandleChange}
                        >
                            <MenuItem value='Decreto'>
                                Decreto
                            </MenuItem>
                            <MenuItem value='Despacho'>
                                Despacho
                            </MenuItem>
                            <MenuItem value='Lei'>
                                Lei
                            </MenuItem>
                            <MenuItem value='Requerimento'>
                                Requerimento
                            </MenuItem>
                            <MenuItem value='Modelo'>
                                Modelo
                            </MenuItem>
                            <MenuItem value='Requisitos'>
                                Requisitos
                            </MenuItem>
                            <MenuItem value='Manual'>
                                Manual
                            </MenuItem>
                            <MenuItem value='Outros'>
                                Outros
                            </MenuItem>
                        </Select>
                    </FormControl>
                    :
                    <FormControl margin='dense' fullWidth>
                        <InputLabel id="demo-simple-select-label">Opção (opcional)</InputLabel>
                        <Select
                            size='small'
                            labelId="opcao"
                            id="opcao"
                            name='opcao'
                            defaultValue={informacao?.opcao ?? ''}
                            label="Opção (opcional)"
                            onChange={HandleChange}
                        >
                            {/* {informacao?.menu === 'INFORMAÇŌES' ?
                                <div> */}
                            <MenuItem value='Desalfandegamento'>
                                Desalfandegamento
                            </MenuItem>
                            <MenuItem value='Licenciamento de Fábricas'>
                                Licenciamento de Fábricas
                            </MenuItem>
                            <MenuItem value='Licenciamento de Fármacias/Ervanárias'>
                                Licenciamento de Fármacias/Ervanárias
                            </MenuItem>
                            <MenuItem value='Importadores'>
                                Importadores
                            </MenuItem>
                            <MenuItem value='Representantes de Laboratório Farmacêuticos'>
                                Representantes de Laboratório Farmacêuticos
                            </MenuItem>
                            <MenuItem value='Produtos Controlados'>
                                Produtos Controlados
                            </MenuItem>
                            <MenuItem value='Pedidos de AIM/Registo de Medicamentos'>
                                Pedidos de AIM/Registo de Medicamentos
                            </MenuItem>
                            {/* </div>
                                :
                                <div> */}
                            <MenuItem value='Ensaios Clinicos'>
                                Ensaios Clinicos
                            </MenuItem>
                            <MenuItem value='Reações Adversas'>
                                Reações Adversas
                            </MenuItem>
                            {/* </div>
                            } */}
                        </Select>
                    </FormControl>
                }

                <TextField
                    margin='dense'
                    type="text"
                    label="Titulo (opcional)"
                    fullWidth
                    size="small"
                    name="titulo"
                    variant="outlined"
                    onChange={HandleChange}
                    defaultValue={informacao?.titulo}
                />

                <br />
            </div>

            <Editor
                setEditorData={setinformacao}
                editorData={informacao?.conteudo}
            />

            <ButtonLeft title={'Salvar'} disabled={!informacao?.conteudo} funcao={HandleCreateInformacao}>
                <Button color='error' style={{ marginRight: 10 }} onClick={() => { props.setCreateInformacao(false) }}>
                    Cancelar
                </Button>
            </ButtonLeft>
        </>
    );
}

export default CreateInformacao;
