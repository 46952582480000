
import { Container } from "@material-ui/core";
// import HeaderSession from "../../utils/headerSession";

export default function Agenda({ element: component, ...rest }) {
    // const classes = useStyles()

    return (
        <Container>
            {/* <HeaderSession title={'GESTÃO DE AGENDA'} /> */}
                    {component}
        </Container>
    )
}