import { Avatar, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import Loading from "../../load/loading";
import { makeStyles } from "@material-ui/core";
import FerramentaNavegacaoExportacao from "../../utils/ferramentaNavegacaoExportacao";
import SearshRepresentante from "./searchRepresentante";
import RegisterAccess from "../../utils/registerAccess";
import ListaVazia from "../../utils/listaVazia";
import HeaderSession from "../../utils/headerSession";
import { RelatorioRepresentantesPdf } from "../exports/gerarelatoriopdf";
import { RelatorioRepresentantesExcel } from "../exports/gerarelatorioexcel";


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#d2d6ff' }, cursor: 'pointer' }
}))

export default function Representantes() {
    const classes = useStyles()
    const navigate = useNavigate()
    const [representantes, setRepresentantes] = useState(useSelector(state => state?.representantes?.data) ?? '')
    const [configLista, setConfigLista] = useState(useSelector(state => state?.representantes?.config) ?? '')
    const [page, setPage] = useState(useSelector(state => state?.representantes.data !== null ? state?.representantes?.config?.previousPage + 1 : 1));
    const dispatch = useDispatch();
    const [perPage, setPerPage] = useState(20)
    const [order, setOrder] = useState('')
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setLoading(true)
        api.get(`/representantes?page=${page}&perPage=${perPage}&sortBy=nome:${order}`)
            .then(response => {
                // console.log(response)
                setConfigLista(response.data);
                setRepresentantes((prevItems) => [...prevItems, ...response.data.representantes]);
                // setFilter(response.data.representantes);

                setLoading(false)
            }).catch(err => { setLoading(false) })

            dispatch({
                type: 'representantes',
                payload: { representantes: representantes, config: configLista },
            }); //dispatch para por os representantes no state da aplicacao e evitar recarregar desnecessariamente
    
    }, [page, perPage, order]);


    return (
        <> 
           <RegisterAccess page={'lista de representantes'}/>
          
           <HeaderSession
                title=' REPRESENTANTES REGISTADOS'
                actions={true}
                handleExportExcel={() => RelatorioRepresentantesExcel(representantes)}
                handleExportPDF={() => RelatorioRepresentantesPdf(representantes)}
            />

            {representantes &&

                <Card style={{ padding: 20, minHeight: '20vh', }}>

                    <SearshRepresentante setRepresentante={setRepresentantes} setPesquisaError={setErrorMessage}/>

                    <Card variant="outlined">
                    <TableContainer style={{ overflowX: 'auto' }} sx={{ width: '100%', overflow: 'hidden' }}>
                        <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                            <TableHead >
                                <TableRow>
                                    <TableCell  ><Typography noWrap> <strong>Foto</strong></Typography></TableCell>
                                    <TableCell  ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                                    <TableCell  ><Typography noWrap> <strong>Genero</strong></Typography></TableCell>
                                    <TableCell  ><Typography noWrap> <strong>Telefone</strong></Typography></TableCell>
                                    <TableCell  ><Typography noWrap> <strong>Provincia</strong></Typography></TableCell>
                                    <TableCell  ><Typography noWrap> <strong>Municipio</strong></Typography></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {representantes.map((representante) => (
                                    <TableRow key={representante.dadosPessoais?._id} onClick={() => navigate(`/admin/get-representante/${representante._id}`)} className={classes.hover}>

                                        <TableCell  >
                                            <Avatar src={representante.dadosPessoais?.fotoURL} />
                                        </TableCell>

                                        <TableCell >{representante.dadosPessoais?.nome}</TableCell>
                                        <TableCell > <Typography noWrap> {representante.dadosPessoais?.genero}</Typography></TableCell>
                                        <TableCell > <Typography noWrap> {representante.dadosPessoais?.tel1}</Typography></TableCell>
                                        <TableCell > <Typography noWrap> {representante.dadosPessoais?.provincia}</Typography></TableCell>
                                        <TableCell > <Typography noWrap> {representante.dadosPessoais?.municipio}</Typography></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    { errorMessage && <ListaVazia text={errorMessage}/>}
                    </Card>

                    <FerramentaNavegacaoExportacao
                        configLista={configLista}
                        lista={representantes}
                        page={page}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        setOrder={setOrder}
                    // exportarExcel={exportarExcel}
                    />
                </Card>
            }

            {loading && <Loading /> }
        </>
    )
}