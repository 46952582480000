
import { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import api from "../../../../../services/api";
import HeaderSession from "../../../../../utils/headerSession";
import Loading from "../../../../../load/loading";
import { useDispatch, useSelector } from 'react-redux';
import SearshEstabelecimento from "../../../../../utils/searchEstabelecimento";
import FerramentaNavegacaoExportacao from "../../../../../utils/ferramentaNavegacaoExportacao";
import ListaSolicitacao from "../listaSolicitacao";
import { useNavigate } from "react-router-dom";

import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import RegisterAccess from "../../../../../utils/registerAccess";
import { SolicitacoesLicenciamentoExcel } from "../../../../exports/gerarelatorioexcel";
import { SolicitacaoLicenciamentoPdf } from "../../../../exports/gerarelatoriopdf";


export default function InspeccaoAgendadaArea() {
  const navigate = useNavigate()
  const user = useSelector(state => state.account.user);
  const [tipo, setTipo] = useState('')
  const [estabelecimentos, setEstabelecimentos] = useState('')
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [order, setOrder] = useState('')
  const [loading, setLoading] = useState(false);
  const [configLista, setConfigLista] = useState('')
  const dispatch = useDispatch();

  // console.log(user)

  useEffect(() => {

    if (isTablet || user?.funcao === 'Inspector') {
      navigate('/admin/minhas-inspeccoes')
    }


    if (user?.funcao === 'Inspector' || user?.funcao === 'Director Geral' || user?.funcao === 'Chefe de Departamento' || user?.funcao === 'CNP') {
    } else {
      dispatch({
        type: 'alert',
        payload: { alert: 'Acesso negado' },
      });
      navigate('/admin/dlif')
    }

    setLoading(true)
    setEstabelecimentos('')
    api.get(`/estabelecimentos?page=${page}&status=Inspecção Agendada&perPage=${perPage}&tipoId=${tipo}&sortBy=nome:${order}`)
      .then(response => {
        setConfigLista(response.data);
        setEstabelecimentos((prevItems) => [...prevItems, ...response.data.estabelecimentos]);


        setLoading(false)
      }).catch(err => { setLoading(false) })

  }, [page, perPage, tipo, order, navigate, user?.funcao, dispatch]);



  return (
    <>
      <RegisterAccess page={'area de inspeccao agendada'} />

      <HeaderSession
        title='ESTABELECIMENTOS A SEREM INSPECCIONADOS'
        actions={true}
        handleExportExcel={() => SolicitacoesLicenciamentoExcel(estabelecimentos)}
        handleExportPDF={() => SolicitacaoLicenciamentoPdf(estabelecimentos)}
      />

      <Card style={{ padding: 20 }}>
        <SearshEstabelecimento setEstabelecimentos={setEstabelecimentos} functionFilter={setTipo} placeholder={'Filtra por nome, número de entrada, data'} />

        {estabelecimentos &&
          <Card variant="outlined">
            <ListaSolicitacao estabelecimentos={estabelecimentos} />

            <FerramentaNavegacaoExportacao
              configLista={configLista}
              lista={estabelecimentos}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              setOrder={setOrder}
            // exportarExcel={exportarExcel} 
            />
          </Card>
        }

        {loading && <Loading />}
      </Card>
    </>
  )
}
