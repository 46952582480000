
// Calendar.js
import { useEffect, useState } from 'react';
import './Calendar.css';
import { IconButton } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Mes from '../../../utils/mes';
import api from '../../../services/api';
import { Button, DialogActions, Grid, MenuItem, TextField, Typography, } from "@mui/material";
import MessageAlert from '../../../messages/messageAlert';
import { useSelector } from "react-redux";
import LoadingBackdrop from '../../../load/loadingBackdrop';
import ListaVazia from '../../../utils/listaVazia';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LoadingShort from '../../../load/loadingShort';
import DateHora from '../../../utils/dateHora';
import RegisterAccess from '../../../utils/registerAccess';
import HeaderSession from '../../../utils/headerSession';

// import { useNavigate } from "react-router-dom";


const CreateAgenda = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    // const navigate = useNavigate()
    const [addAgenda, setAddAgenda] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [load, setLoad] = useState(false)
    const user = useSelector(state => state.account.user);
    const [horarios, setHorarios] = useState('')
    const [agendaDiaria, setAgendaDiaria] = useState(true)

    const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const dayNames = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    const horariosCreateAgenda = ['08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30'];

    const [agendas, setagendas] = useState('')
    const [isMarked, setIsMarked] = useState([])
    const [loadAgenda, setLoadAgenda] = useState(false)

    // console.log(addAgenda)

    useEffect(() => {
        setLoadAgenda(true)

        api.get(`/agendas?ano=${currentDate.getFullYear()}&mes=${currentDate.getMonth() + 1}&perPage=200`)
            .then(response => {
                // console.log(response)
                setagendas(response.data.agendas);
                setIsMarked(response.data.agendas)
                setLoadAgenda(false)
            }).catch(error => 'console.log(error)')
    }, [currentDate, messageSuccess])

    const getHorarios = (day) => {
        setHorarios(agendas.filter(agenda => agenda.dia === day))
        // console.log(horarios)
    };

    function HandleChange(e) {
        setAddAgenda({ ...addAgenda, [e.target.name]: e.target.value })
    }

    const createAgenda = () => {
        setMessageSuccess('')
        setMessageError('')
        setLoad('A criar agenda')

        api.post('/agenda/create', {
            ...addAgenda,
            'tipo': 'Licenciamento',
            'ano': currentDate.getFullYear(),
            'mes': currentDate.getMonth() + 1,
            'dia': selectedDate,
            'dataDisponivel': new Date(addAgenda.dataDisponivel).toISOString('en-Us', { timeZone: 'Africa/Luanda'}),
            'userId': user._id,
        }).then(response => {
            // console.log(response)
            setLoad(false)
            setMessageSuccess(response.data.msg)
        }).catch(err => {
            // console.log(err)
            setLoad(false)
            setMessageError(err.response.data.message)
        })
    }

    const createAgendaDiaria = () => {
        setMessageSuccess('')
        setMessageError('')
        setLoad('A criar agenda do dia')

        api.post('/agenda/create-daily', {
            ...addAgenda,
           'tipo': 'Licenciamento',
            'ano': currentDate.getFullYear(),
            'mes': currentDate.getMonth() + 1,
            'dia': selectedDate,
            'dataDisponivel': new Date(addAgenda.dataDisponivel).toISOString('en-Us', { timeZone: 'Africa/Luanda'}),
            'userId': user._id,

        }).then(response => {
            setLoad(false)
            // console.log(addAgenda)
            setMessageSuccess(response.data.msg)
        }).catch(err => {

            // console.log(err)
            setLoad(false)
            setMessageError(err.response.data.message)
        })
    }


    const renderCalendar = () => {
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        const firstDayIndex = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
        const days = [];

        for (let i = 1; i <= lastDay; i++) {
            days.push({
                dia: i,
                tipo: '',
                // Adicione outras propriedades conforme necessário
                // Exemplo: isWeekend: i % 6 === 0, // Marca os fins de semana
            });
        }

        const calendarDays = [];
        for (let i = 0; i < firstDayIndex; i++) {
            calendarDays.push(<div key={`empty-${i}`} className="empty-day"></div>);
        }

        days.forEach((dayObj) => {
            const day = dayObj.dia;
            const isDisabled = new Date(currentDate.getFullYear(), currentDate.getMonth(), day) < new Date();
            const isMarkedVerify = isMarked.find((e) => e.dia === day);

            calendarDays.push(
                <div
                    key={day}
                    style={selectedDate === day ? { border: '2px solid red', transition: '.5s', transform: 'scale(1.2)' } : null}
                    className={
                        `day ${isDisabled ? 'disabled' : ''}
                         ${loadAgenda ? 'disabled' : ''}
                         ${isMarkedVerify?.tipo === 'Renovação' && 'renovacao'}
                         ${isMarkedVerify?.tipo === 'Licenciamento' && 'licenciamento'}
                         `}
                    onClick={() => { handleDayClick(day); getHorarios(day) }}
                >
                    {/* {isMarkedVerify ? <span className="event-day">{day}</span> : day} */}
                    {day}
                </div>
            );
        });

        return calendarDays;
    };

    const handleDayClick = (day) => {
        if (new Date(currentDate.getFullYear(), currentDate.getMonth(), day) >= new Date()) {
            setSelectedDate(day);
        } else {
            // alert('Você não pode selecionar um dia passado.');
            alert('Não é possível adicionar agenda para o passado dia ' + day);
        }
    };

    return (
        <>
           <RegisterAccess page={'Criar agenda'}/>
           <HeaderSession title={'GESTÃO DE AGENDA'} />

            <div className="calendar" style={{ padding: 20 }}>
                <Typography style={{ fontSize: 20, marginBottom: 10 }}>
                    CRIAR AGENDA
                </Typography>

                <div className="header">
                    <IconButton onClick={() => { setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)); setSelectedDate(null) }}>
                        <NavigateBeforeIcon color='warning' />
                    </IconButton>

                    <h2>{`${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`}</h2>

                    <IconButton onClick={() => { setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)); setSelectedDate(null) }}>
                        <NavigateNextIcon color='warning' />
                    </IconButton>
                </div>

                <div className="weekdays">
                    {dayNames.map((day, index) => (
                        <div key={day}
                            className={
                                `weekday 
                        ${index === 1 && 'licenciamento'}
                        ${index === 3 && 'licenciamento'}
                            `}>
                            <span translate="no">{day}</span>
                        </div>
                    ))}
                </div>

                <div className="days" style={{ position: 'relative' }}>
                    {loadAgenda &&
                        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <LoadingShort />
                        </div>
                    }
                    {/* Renderização dos dias do calendário */}
                    {renderCalendar()}
                </div>

                <div className="tags">
                    {/* <div>
                        <div className="tag-color1"></div>Renovação
                    </div> */}
                    <div>
                        <div>
                            <div className="tag-color2"></div>Licenciamento
                        </div>
                    </div>
                </div>

                {selectedDate !== null && (
                    <div onMouseMove={() => getHorarios(selectedDate)} className={`time-table ${selectedDate !== null ? 'show' : ''}`}>
                        <br />
                        {/* Tabela de horários */}
                        <h3>Horários disponíveis para {selectedDate} de <Mes mes={currentDate.getMonth() + 1} /></h3>
                        {/* INPUT PARA DAR FOCO NO COMPONENTE ASSIM QUE FOR EXIBIDO */}
                        <input autoFocus type="text" style={{ opacity: 0, height: 1 }} />

                        <table>
                            <thead>
                                <tr>
                                    <th>Horário</th>
                                    <th>Tipo de agenda</th>
                                    <th>Status</th>
                                    <th>Data para disponibilizar</th>
                                </tr>
                            </thead>

                            <tbody className='time-table-tbody'>
                                {horarios.map((horario) => (
                                    <tr key={horario._id}>
                                        <td> {horario?.hora < 10 ? `0${horario?.hora}` : horario?.hora}:{horario?.minuto < 10 ? `0${horario?.minuto }` : horario?.minuto}</td>
                                        <td>{horario.tipo}</td>
                                        <td>{horario.status}</td>
                                        <td>
                                            {horario?.dataDisponivel &&
                                                <DateHora date={horario?.dataDisponivel} />
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {horarios.length < 1 && <ListaVazia text={`Nenhum horário encontrado para dia ${selectedDate}`} />}

                        <>
                            <br />
                            <h3>Adicionar Horário para {selectedDate} de <Mes mes={currentDate.getMonth() + 1} /></h3>

                            <LoadingBackdrop open={!!load} text={load} />
                            {messageSuccess && <MessageAlert message={messageSuccess} type={'success'} />}
                            {messageError && <MessageAlert message={messageError} type={'error'} />}


                            <FormControlLabel control={<Checkbox checked={agendaDiaria} />} label={'Criar agenda completa do dia'}
                                labelPlacement='start'
                                style={{ display: 'flex', justifyContent: 'end', margin: 2 }}
                                onClick={() => setAgendaDiaria(!agendaDiaria)}
                            />

                            <Grid container>
                                <Grid m xs={12} md item >
                                    {/* <TextField
                                        required
                                        type="text"
                                        label="Tipo de Agenda"
                                        select
                                        fullWidth
                                        size="small"
                                        name="ç"
                                        variant="outlined"
                                        onChange={HandleChange}
                                        defaultValue={'Licenciamento'}
                                    >
                                        <MenuItem key={1} value={'ç'} name={'tipo'}>
                                            Licenciamento
                                        </MenuItem>
                                        <MenuItem key={2} value={'Renovação'} name={'tipo'}>
                                            Renovação
                                        </MenuItem>
                                    </TextField> */}
                                </Grid>

                                <Grid m xs={12} md item >
                                    <TextField
                                        required
                                        type="datetime-local"
                                        fullWidth
                                        label="Disponibilizar aos:"
                                        size="small"
                                        name="dataDisponivel"
                                        variant="outlined"
                                        onChange={HandleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                {!agendaDiaria &&
                                    <Grid m xs={12} md item >
                                        <TextField
                                            required
                                            type="text"
                                            label="Hora"
                                            select
                                            fullWidth
                                            size="small"
                                            name="hora"
                                            variant="outlined"
                                            onChange={HandleChange}
                                            defaultValue={'08:00'}
                                        >
                                            {horariosCreateAgenda.map((horario) => (
                                                <MenuItem key={horario} value={horario} name={'tipo'}>
                                                    {horario}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                }
                            </Grid>

                            {agendaDiaria &&
                                <>
                                    <p style={{ margin: 10, fontSize: 12 }}>
                                        <strong>Nota:</strong> Se não preencher a hora inicio e a hora de término,
                                        a agenda será cria automaticamente de 08:00 até as 14:30
                                    </p>
                                    <Grid container>
                                        <Grid m xs={12} md item >
                                            <TextField
                                                required
                                                type="text"
                                                label="Hora inicio do dia"
                                                select
                                                fullWidth
                                                size="small"
                                                name="horaInicio"
                                                variant="outlined"
                                                onChange={HandleChange}
                                                defaultValue={'08:00'}
                                            >
                                                {horariosCreateAgenda.map((horario) => (
                                                    <MenuItem key={horario} value={horario} name={'tipo'}>
                                                        {horario}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid m xs={12} md item >
                                            <TextField
                                                required
                                                type="text"
                                                label="Hora término do dia"
                                                select
                                                fullWidth
                                                size="small"
                                                name="horaTermino"
                                                variant="outlined"
                                                onChange={HandleChange}
                                                defaultValue={'12:00'}
                                            >
                                                {horariosCreateAgenda.map((horario) => (
                                                    <MenuItem key={horario} value={horario} name={'tipo'}>
                                                        {horario}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </>
                            }

                            <DialogActions>
                                {/* disabled={!addAgenda.hora} */}
                                <Button onClick={() => agendaDiaria ? createAgendaDiaria() : createAgenda()} variant="contained" style={{ background: '#85287e' }}>
                                    Salvar
                                </Button>
                            </DialogActions>
                        </>
                    </div>
                )}
            </div>

            {selectedDate === null && <Typography variant='h6' marginTop={10} align='center'>SELECIONA NO CALENDARIO O DIA EM QUE DESEJA CRIAR AGENDA</Typography>}
        </>
    );
};

export default CreateAgenda;
