import { makeStyles } from '@material-ui/core';
import { Box, Container, Skeleton } from '@mui/material';
import { useEffect } from 'react'
import { useState } from "react";
import api from '../../services/api';
import HeaderSession from '../../utils/headerSession';
import RegisterAccess from '../../utils/registerAccess';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        paddingTop: 20,
        // textAlign: 'center',
        color: '#67348d'
    },
}))

function SkeletonCard() {
    return (
        <Box>
            <Skeleton animation="wave" variant="rectangular" width={210} />
            <Box sx={{ pt: 0.5 }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" width="60%" />
            </Box>
        </Box>
    );
}

export default function GetSiteInfor({ menu = '', opcao = '', tipoFile = '', menuTitle }) {
    const classes = useStyles()
    const [load, setLoad] = useState(false)
    const [sitInfos, setSiteInfos] = useState('')

    useEffect(() => {
        setLoad(true);
        api.get(`/site-infos?menu=${menu}&opcao=${opcao}&tipoFile=${tipoFile}`)
            .then(res => {
                setSiteInfos(res.data.siteInfos);
            })
            .catch(() => {
            }).finally(() => {
                setLoad(false);
            });
    }, [menu, opcao, tipoFile])


    return (
        <Container style={{ maxWidth: 800, minHeight: '90vh', marginBottom: 20 }}>

            <RegisterAccess page={menuTitle} />
            <HeaderSession title={menuTitle} />

            {load ?
                [...Array(10)].map((_, index) => (<SkeletonCard key={index} />))
                :
                sitInfos.length > 0 && (
                    sitInfos.map((info) => (
                        <div key={info._id} >

                            <h1 className={classes.title} style={{ textAlign: menu === 'SOBRE NÓS' && 'center' }}>
                                {info?.titulo}
                            </h1>

                            <div className={classes.root} style={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: info?.conteudo }} />

                            {info?.anexo && (
                                info?.anexo.includes("png") ?
                                    <img width={800} style={{ width: '100%', objectFit: 'cover', }} src={info?.anexo} alt="" />
                                    :
                                    <a href={info?.anexo} target={'_blank'} rel="noreferrer">Ver anexo </a>
                            )}
                        </div>
                    )))}
        </Container>
    )
}

