import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { DialogActions, Collapse } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
// import RedesSocias from './redesSociais/redesSocias';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import api from '../services/api';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import LoadingShort from '../load/loadingShort';
import BuscaEstabelecimento from './buscaEstabelecimento';
import CloseIcon from '@mui/icons-material/Close';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

const pages = [
    { 'page': 'Início', 'link': '/' },
    { 'page': 'Sobre Nós', 'link': '/sobre-nos' },
    { 'page': 'Farmacovigilância', 'link': '/farmacovigilancia' },
    { 'page': 'Legislação e Documentos', 'link': '/legislacao-e-documentos' },
    { 'page': 'Contactos', 'link': '/contactos' },
];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function NavBar() {
    const [open, setOpen] = React.useState(false);
    const agendaSelected = useSelector(state => state.agenda.data)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [load, setLoad] = React.useState(false);
    const { agendaId } = useParams()
    // const url = useLocation();
    const [logo, setLogo] = React.useState(true);
    const [openMenu, setOpenMenu] = React.useState(false);
    const handleNavMenu = () => {
        setOpenMenu(!openMenu)
        setLogo(!logo)
    };

    // COMTROLO DE AGENDA SELECIONADA, SE NAO FOR CONCULIDO O CADASTRO DO ESTABELECIMENTO DEVE DISPONIBILIZAR NOVAMENTE A AGENDA
    React.useEffect(() => { agendaSelected?.status === 'Ocupado' && setOpen(true) }, [agendaId, agendaSelected?.status])

    const removeAgenda = () => {
        setLoad(true)
        api.patch(`/agenda/change-status`, { agendaId: agendaSelected?._id, status: 'Disponível' })
            .then(response => {
                // console.log(response)
                localStorage.removeItem(agendaId);
                dispatch({
                    type: 'agenda',
                    payload: { agenda: null }
                })

                setLoad(false)
                setOpen(false);
                // navigate('/')
            }).catch(error => '')
    }

    return (
        <>
            <AppBar position="fixed" style={{ background: '#85287e', boxShadow: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                <Box onClick={() => navigate('/')} style={{ background: '#fffffff8', padding: '10px 25px', cursor: 'pointer', display: logo ? '' : 'none' }} >
                    {/* <img src="/img/logo/logoMinsa.svg" alt="" style={{ marginRight: 20 }} width={130} /> */}
                    <img src="/img/logo/logoArmed.svg" alt="" width={120} />
                </Box>

                <Container maxWidth="xl" style={{ padding: 0 }}>
                    <Toolbar disableGutters>

                        <Box minWidth={750} alignItems={'center'} justifyContent={'center'} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page, index) => (
                                <React.Fragment key={page.page}>
                                    <Button
                                        onClick={() => navigate(page.link)}
                                        // disabled={url.pathname === `/licenciamento/${agendaId}`}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        {page.page}
                                    </Button>
                                    {index < 4 &&
                                        < span style={{ color: '#eaa925', margin: 3 }}>|</span >
                                    }
                                </React.Fragment>
                            ))}
                        </Box>

                        <Box sx={{ display: { xs: 'flex', md: 'none', flexGrow: 1, } }} />


                        <BuscaEstabelecimento />
                        {/* <Box style={{ minWidth: 160, display: 'flex', alignItems: 'center' }} sx={{ flexGrow: 0 }}>
                            <RedesSocias />
                        </Box> */}

                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleNavMenu}
                                color="inherit"
                            >
                                {openMenu ? <CloseIcon /> : <MenuIcon />}
                            </IconButton>
                        </Box>

                    </Toolbar>

                    <Collapse sx={{ display: { xs: 'flex', md: 'none' } }} style={{ transition: '.3s' }} in={openMenu} timeout="auto" unmountOnExit>
                        {pages.map((page) => (
                            <MenuItem key={page.page} onClick={() => navigate(page.link)}>
                                <Typography textAlign="center">{page.page}</Typography>
                            </MenuItem>
                        ))}
                    </Collapse>

                </Container>
            </AppBar>



            {/* MODAS DE CONTROLO DE LICENCIAMENTO EM ANDAMENTO */}
            <BootstrapDialog
                scroll='body'
                fullWidth
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <div style={{ padding: 20 }}>
                    <Typography textAlign="center" marginBottom={4}>
                        <WarningAmberOutlinedIcon fontSize='large' color='warning' />
                        <br />
                        Tens um processo licenciamento de estabelecimento em andamento, deseja continuar ou cancelar?
                    </Typography>

                    <DialogActions>
                        <Button onClick={() => { navigate(`/licenciamento/${agendaSelected?._id}`); setOpen(false) }} variant="contained" style={{ background: '#85287e' }} >
                            Continuar
                        </Button>
                        <Button onClick={removeAgenda} variant="contained" color='error' >
                            {load
                                ? <LoadingShort />
                                : 'Cancelar'
                            }
                        </Button>
                    </DialogActions>
                </div>
            </BootstrapDialog>
        </>
    );
}
export default NavBar;
