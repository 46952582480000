import React, { useState, useEffect } from 'react'
import './erro.css'
import { Box, Skeleton, Button } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const ErroCarregamento: React.FC = () => {
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(true);
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="64vh" position="relative">
            {showMessage && (
                <div style={{ position: 'absolute', zIndex: '2' }} className='erro-div'>
                    <ReportProblemOutlinedIcon className='iconError' />
                    <p>Opa!... Alguma coisa correu mal.</p>
                    <Button onClick={() => window.location.reload()} variant="outlined" className='btn'>Recarregar Página</Button>
                </div>
            )}
            <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" style={{ zIndex: '1' }} />
        </Box>
    )
}

export default ErroCarregamento
