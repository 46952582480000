
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CountUp from 'react-countup';
import { useEffect, useState } from "react";
import api from "../services/api";


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '27%',
        minWidth: 180,
        height: 120,
        margin: 10, 
        border: '1px solid #e0e0e0',
        borderRadius: 10,
        transition: '.5s',
        '&:hover': { background: '#e0e0e0', transform: 'scale(1.1)', },

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },

    count: {
        fontFamily: 'Work Sans',
        fontSize: 22,
        fontWeight: 'bold',
        color: '#284a62',
        transition: '.5s',
        // '&:hover': { fontSize: 40 }
    },
}))

export default function ServicesMenuControlGeral(props) {
    const classes = useStyles()
    const [farmacias, setFarmacias] = useState([])
    const [depositos, setDepositos] = useState([])
    const [total, setTotal] = useState([])

    useEffect(() => {
        Promise.all([
            api.get(`/estabelecimentos?provincia=${props.provincia}&tipoId=663a11d7b335356682ef6156&count=true`),
            api.get(`/estabelecimentos?provincia=${props.provincia}&tipoId=660176d0075c22f85a87841f&count=true`),
            api.get(`/estabelecimentos?provincia=${props.provincia}&count=true`),

        ]).then(response => {
            setFarmacias(response[0].data.totalItems);
            setDepositos(response[1].data.totalItems);
            setTotal(response[2].data.totalItems);

        }).catch(error => '')
    }, [props.provincia])

    return (
        <CardActionArea className={classes.root} >
            <Link style={{ textDecoration: 'none', color:'#292b2b' }} to={'/admin/provincia/'+props.provincia}>
                <CardContent style={{ display: 'flex' }}>
                    <div >
                        <img src={props.icone} alt={props.provincia} width={70} />
                    </div>

                    <div style={{ marginLeft: 10, width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                            <Typography style={{ flexGrow: 1}}>
                                {props.provincia.toUpperCase()}
                            </Typography>

                            <Typography  align="right" className={classes.count}>
                                {/* BIBLIOTECA PARA APLICAR EFEITO DE CONTAGEM CRESCENTE */}
                                <CountUp end={total} />
                            </Typography>
                        </div>

                        <hr style={{ border: '1px solid #85287e', margin: '5px 0' }} />

                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <div>
                                <small >
                                    FAR.
                                </small>
                                <Typography align="right" >
                                <CountUp end={farmacias} />
                                </Typography>
                            </div>
                            <div>
                                <small >
                                    DEP.
                                </small>
                                <Typography align="right" >
                                    <CountUp end={depositos} />
                                </Typography>
                            </div>
                            <div>
                                <small >
                                    ###
                                </small>
                                <Typography align="right" >
                                <CountUp end={0} />
                                </Typography>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Link>
        </CardActionArea>
    )
}