import { Button, DialogActions, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import LoadingShort from '../../../../load/loadingShort';
import api from '../../../../services/api';
import FazerObservacoes from '../fazerObservacoes.';
import RegisterAccess from '../../../../utils/registerAccess';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

const ReenviarLinkReprovacao = (props) => {
    // const [open, ] = useState(props.load);
    const [obs, setObs] = useState('');
    const [load, setLoad] = useState(false);
    const [novoEmail, setNovoEmail] = useState(null);
    const baseUrl = window.location.origin;

    const handleClose = () => {
        props.setOpenReenviarEmail(false);
    };

    async function senEmail() {
        setLoad(true);
        props.setMessageSuccess('');
        props.setMessageError('');

        const msg = {
            to: novoEmail?.toLowerCase() ?? props.estabelecimento?.email?.toLowerCase(),
            subject: 'Estado da solicitação de licenciamento de estabelecimento faramacêutico',
            html: `<h1>O processo está no estado: ${props.estabelecimento?.status}</h1>
            <p>
            Este é um email de acompanhamento da solicitação 
            de lienciamento do estabelecimento <b> ${props.estabelecimento?.nome} </b>
            com o número de entrada <b> ${props.estabelecimento?.numeroEntrada} </b>
            que submeteu na <b>ARMED</b> 
            </p>

            ${obs ? `
                </p>
                Observação:
                <br/>
                ${obs}
                </p>
                `
                    :
                    ''
                }

           <p>Para fazer as devidas alterações acesse o link abaixo:</p>
           <a href='${baseUrl}/edite-processo-estabelecimento/${props.estabelecimento._id}'>Processo_${props.estabelecimento?.nome}</a>
    
            <p><b>Nota:</b> Este e-mail foi gerado automaticamente pela plataforma <b>ARMED</b></p>
     `,
        };
        api.post('/email/send', msg)
            .then(response => {
                props.setMessageSuccess('Foi enviado com sucesso.');
                // console.log(response)
            }).catch(err => {

                props.setMessageError('Ocorreu um erro ao enviar o E-mail.');
                // console.log(err) 
            }).finally(() => { setLoad(false); props.setOpenReenviarEmail(false); })
    }

    return (
        <>
            <BootstrapDialog
                scroll='body'
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.openReenviarEmail}
            >
                <RegisterAccess page={'reenviar link de reprovação'} />

                <div style={{ padding: 20 }}>

                    <Typography>
                        <strong>Enviar link de reprovação por e-mail</strong>
                        <br />
                        <small>OBS: pode mudar o email</small>
                    </Typography>

                    <TextField
                        fullWidth
                        type='email'
                        size='small'
                        defaultValue={props.estabelecimento?.email?.toLowerCase()}
                        onChange={(e) => setNovoEmail(e.target.value)}
                    />

                    <FazerObservacoes obs={obs} setObs={setObs} titulo={'(caso seja necessário fazer alguma observação)'} />

                    <DialogActions>
                        {/* <Button title='Cancelar' onClick={() => props.setOpenReenviarEmail(false)} size="small">Cancelar</Button> */}
                        <Button onClick={() => senEmail()} size="small">
                            {load ? <LoadingShort /> : 'Enviar'}
                        </Button>
                    </DialogActions>
                    {/* } */}
                </div>
            </BootstrapDialog>
        </>
    );
}

export default ReenviarLinkReprovacao;
