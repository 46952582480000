
import { makeStyles } from '@material-ui/core';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
    root: {
        transition: '.3s',
        '&:hover': {
            width: 25
        },
    },
})

export default function ButtonEditar({ setEdit, edit }) {
    const classes = useStyles()
    // const user = useSelector(state => state.account.user);

    return (
        <Tooltip title={edit ? 'Fechar' : 'Editar'}>
            {edit ?
                <IconButton onClick={() => { setEdit(false) }} size="small" style={{ marginLeft: 'auto' }}>
                    <CloseIcon />
                </IconButton>
                :
                <IconButton onClick={() => { setEdit(true) }} size="small" style={{ marginLeft: 'auto' }}>
                    <img src="/icones/editar.svg" alt="" width={20} className={classes.root} />
                </IconButton>
            }
        </Tooltip>
    )
}