
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


pdfMake.vfs = pdfFonts.pdfMake.vfs;
/** REINDERIZAÇÃO DE IMAGEM, PARA CONVERÇÃO **/
function getBase64Image(img) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
/** ESTABELECIMENTOS LICENCIADOS **/
const EstabelecimentosLicenciadospdf = (dados) => {
    const gararTabela = () => {
        const table = {
            headerRows: 1,
            widths: ['auto', '*', '*', '*', '*', '*', '*'],
            body: [
                [
                    { text: '#', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Nome', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Tipo', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Nº De Processo', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Director Tecnico', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Provincia', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Estado', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                ],
                ...dados.slice(0, 50).map((estabelecimento, index) => [
                    { text: `${index + 1}`, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.nome, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.tipo?.actividade, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.numeroProcesso, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.directorTecnico?.dadosPessoais?.nome, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.provincia, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.status, alignment: 'left', fontSize: 10 },
                ]),
            ],
        };
        return table;
    };
    const evestosDoc = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [50, 30, 50, 30],
        footer: {
            columns: [
                '',
                {
                    text: 'Gerado pelo sistema da Armed em: ' + new Date().toLocaleString(),
                    alignment: 'center',
                    fontSize: 8
                },
            ]
        },
        content: [
            {
                text: 'Lista de Estabelecimentos Licenciados',
                style: 'cabecalho',
                alignment: 'center',
            },
            {
                table: gararTabela(dados),
            },
        ],
        styles: {
            cabecalho: {
                margin: [0, 10, 0, 20],
                fontSize: 14,
                bold: true,
            }
        },
    };
    const pdfDoc = pdfMake.createPdf(evestosDoc);
    pdfDoc.open();
};

/** SOLICIÇAO DE LICENCIAMENTO **/
const SolicitacaoLicenciamentoPdf = (dados) => {
    const gararTabela = () => {
        const table = {
            headerRows: 1,
            widths: ['auto', '*', '*', '*', '*', '*', '*', '*', '*'],
            body: [
                [
                    { text: '#', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Nome', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Tipo', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Nº De Entrada', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Director Tecnico', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Solicitação', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Estado', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Data De Inscrição', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Ultima intervenção', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                ],
                ...dados.slice(0, 50).map((estabelecimento, index) => [
                    { text: `${index + 1}`, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.nome, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.tipo?.designacao, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.numeroEntrada, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.directorTecnico?.dadosPessoais?.nome, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.tipoEntrada, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.status, alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.createdAt.slice(0, 10), alignment: 'left', fontSize: 10 },
                    { text: estabelecimento?.updatedAt.slice(0, 10), alignment: 'left', fontSize: 10 },
                ]),
            ],
        };
        return table;
    };
    const evestosDoc = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [50, 30, 50, 30],
        footer: {
            columns: [
                '',
                {
                    text: 'Gerado pelo sistema da Armed em: ' + new Date().toLocaleString(),
                    alignment: 'center',
                    fontSize: 8
                },
            ]
        },
        content: [
            {
                text: 'Lista de Solicitações de Licenciamento',
                style: 'cabecalho',
                alignment: 'center',
            },
            {
                table: gararTabela(dados),
            },
        ],
        styles: {
            cabecalho: {
                margin: [0, 10, 0, 20],
                fontSize: 14,
                bold: true,
            }
        },
    };
    const pdfDoc = pdfMake.createPdf(evestosDoc);
    pdfDoc.open();
};

/** RELATORIO EMPRESAS **/
const RelatorioEmpresasPdf = (dados) => {
    const gararTabela = () => {
        const table = {
            headerRows: 1,
            widths: ['auto', '*', '*', '*'],
            body: [
                [
                    { text: '#', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Nome', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Provincia', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Municipio', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                ],
                ...dados.slice(0, 50).map((empresa, index) => [
                    { text: `${index + 1}`, alignment: 'left', fontSize: 10 },
                    { text: empresa?.nome, alignment: 'left', fontSize: 10 },
                    { text: empresa?.provincia, alignment: 'left', fontSize: 10 },
                    { text: empresa?.municipio, alignment: 'left', fontSize: 10 },
                ]),
            ],
        };
        return table;
    }
    const evestosDoc = {
        pageSize: 'A4',
        pageMargins: [50, 30, 50, 30],
        footer: {
            columns: [
                '',
                {
                    text: 'Gerado pelo sistema da Armed em: ' + new Date().toLocaleString(),
                    alignment: 'center',
                    fontSize: 8
                },
            ]
        },
        content: [
            {
                text: 'Lista de Empresas',
                style: 'cabecalho',
                alignment: 'center',
            },
            {
                table: gararTabela(dados),
            },
        ],
        styles: {
            cabecalho: {
                margin: [0, 10, 0, 20],
                fontSize: 14,
                bold: true,
            }
        },
    };
    const pdfDoc = pdfMake.createPdf(evestosDoc);
    pdfDoc.open();
};

/** RELATORIO DE REPRESENTANTES **/
const RelatorioRepresentantesPdf = (dados) => {
    const gararTabela = () => {
        const table = {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
                [
                    { text: '#', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Nome', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Email', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Telefone', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Provincia', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Municipio', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                ],
                ...dados.slice(0, 50).map((representante, index) => [
                    { text: `${index + 1}`, alignment: 'left', fontSize: 10 },
                    { text: representante?.dadosPessoais?.nome, alignment: 'left', fontSize: 10 },
                    { text: representante?.dadosPessoais?.email, alignment: 'left', fontSize: 10 },
                    { text: representante?.dadosPessoais?.tel1, alignment: 'left', fontSize: 10 },
                    { text: representante?.dadosPessoais?.provincia, alignment: 'left', fontSize: 10 },
                    { text: representante?.dadosPessoais?.municipio, alignment: 'left', fontSize: 10 },
                ]),
            ],
        };
        return table;
    };
    const evestosDoc = {
        pageSize: 'A4',
        pageMargins: [50, 30, 50, 30],
        footer: {
            columns: [
                '',
                {
                    text: 'Gerado pelo sistema da Armed em: ' + new Date().toLocaleString(),
                    alignment: 'center',
                    fontSize: 8
                },
            ]
        },
        content: [
            {
                text: 'Lista de Representantes',
                style: 'cabecalho',
                alignment: 'center',
            },
            {
                table: gararTabela(dados),
            },
        ],
        styles: {
            cabecalho: {
                margin: [0, 10, 0, 20],
                fontSize: 14,
                bold: true,
            }
        },
    };
    const pdfDoc = pdfMake.createPdf(evestosDoc);
    pdfDoc.open();
};

/** RELATORIO DE DIRECTORES TECNICOS **/
const RelatorioDirectoresTecnicosPdf = (dados) => {
    const gararTabela = () => {
        const table = {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
                [
                    { text: '#', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Nome', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Carteira', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Categoria', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Telefone', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Provincia', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Municipio', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                ],
                ...dados.slice(0, 50).map((diretor, index) => [
                    { text: `${index + 1}`, alignment: 'left', fontSize: 10 },
                    { text: diretor?.dadosPessoais?.nome, alignment: 'left', fontSize: 10 },
                    { text: diretor?.numeroCarteira, alignment: 'left', fontSize: 10 },
                    { text: diretor?.categoria?.designacao, alignment: 'left', fontSize: 10 },
                    { text: diretor?.dadosPessoais?.tel1, alignment: 'left', fontSize: 10 },
                    { text: diretor?.dadosPessoais?.provincia, alignment: 'left', fontSize: 10 },
                    { text: diretor?.dadosPessoais?.municipio, alignment: 'left', fontSize: 10 },
                ]),
            ],
        };
        return table;
    };
    const evestosDoc = {
        pageSize: 'A4',
        pageMargins: [50, 30, 50, 30],
        footer: {
            columns: [
                '',
                {
                    text: 'Gerado pelo sistema da Armed em: ' + new Date().toLocaleString(),
                    alignment: 'center',
                    fontSize: 8
                },
            ]
        },
        content: [
            {
                text: 'Relatorio de Directores Tecnicos',
                style: 'cabecalho',
                alignment: 'center',
            },
            {
                table: gararTabela(dados),
            },
        ],
        styles: {
            cabecalho: {
                margin: [0, 10, 0, 20],
                fontSize: 14,
                bold: true,
            }
        },
    };
    const pdfDoc = pdfMake.createPdf(evestosDoc);
    pdfDoc.open();
};

/** RELATORIO GESTAO DE AGENDA **/
const RelatorioGestaoAgendaPdf = (dados) => {
    const gararTabela = () => {
        const table = {
            headerRows: 1,
            widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', '*'],
            body: [
                [
                    { text: '#', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Tipo de Agenda', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Horario', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Dia', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Mes', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Ano', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Estado', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                    { text: 'Data para disponibilizar', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 11, bold: true },
                ],
                ...dados.slice(0, 50).map((agenda, index) => [
                    { text: `${index + 1}`, alignment: 'left', fontSize: 10 },
                    { text: agenda?.tipo, alignment: 'left', fontSize: 10 },
                    { text: agenda?.hora, alignment: 'left', fontSize: 10 },
                    { text: agenda?.dia, alignment: 'left', fontSize: 10 },
                    { text: agenda?.mes, alignment: 'left', fontSize: 10 },
                    { text: agenda?.ano, alignment: 'left', fontSize: 10 },
                    { text: agenda?.status, alignment: 'left', fontSize: 10 },
                    { text: agenda?.dataDisponivel.slice(0, 10).split('-').reverse().join('/'), alignment: 'left', fontSize: 10 },
                ]),
            ],
        };
        return table;
    };
    const evestosDoc = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [50, 30, 50, 30],
        footer: {
            columns: [
                '',
                {
                    text: 'Gerado pelo sistema da Armed em: ' + new Date().toLocaleString(),
                    alignment: 'center',
                    fontSize: 8
                },
            ]
        },
        content: [
            {
                text: 'Relatorio Gestao de Agendas',
                style: 'cabecalho',
                alignment: 'center',
            },
            {
                table: gararTabela(dados),
            },
        ],
        styles: {
            cabecalho: {
                margin: [0, 10, 0, 20],
                fontSize: 14,
                bold: true,
            }
        },
    };
    const pdfDoc = pdfMake.createPdf(evestosDoc);
    pdfDoc.open();
};

/** RELATORIO ADIMINISTRADORES DO SISTEMA **/
const RelatorioAdministadoresPdf = (dados) => {
    const gararTabela = () => {
        const table = {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: [
                [
                    { text: '#', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Nome', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Departamento', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Seccao', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Agente', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Funcao', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'BI', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Email', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Telefone1', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Telefone2', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Rua', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Bairro', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Distrito / Municipio', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                    { text: 'Provincia', fillColor: '#F1F1F1', style: 'header', color: '#111', alignment: 'left', fontSize: 9, bold: true },
                ],
                ...dados.slice(0, 50).map((representante, index) => [
                    { text: `${index + 1}`, alignment: 'left', fontSize: 8 },
                    { text: representante?.dadosPessoais?.nome, alignment: 'left', fontSize: 8 },
                    { text: representante?.departamento, alignment: 'left', fontSize: 8 },
                    { text: representante?.seccao, alignment: 'left', fontSize: 8 },
                    { text: representante?.numeroAgente, alignment: 'left', fontSize: 8 },
                    { text: representante?.funcao, alignment: 'left', fontSize: 8 },
                    { text: representante?.dadosPessoais?.numeroBI, alignment: 'left', fontSize: 8 },
                    { text: representante?.dadosPessoais?.email, alignment: 'left', fontSize: 8 },
                    { text: representante?.dadosPessoais?.tel1, alignment: 'left', fontSize: 8 },
                    { text: representante?.dadosPessoais?.tel2, alignment: 'left', fontSize: 8 },
                    { text: representante?.dadosPessoais?.rua, alignment: 'left', fontSize: 8 },
                    { text: representante?.dadosPessoais?.bairro, alignment: 'left', fontSize: 8 },
                    { text: representante?.dadosPessoais?.municipio, alignment: 'left', fontSize: 8 },
                    { text: representante?.dadosPessoais?.provincia, alignment: 'left', fontSize: 8 },
                ]),
            ],
        };
        return table;
    };
    const evestosDoc = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [10, 30, 10, 30],
        footer: {
            columns: [
                '',
                {
                    text: 'Gerado pelo sistema da Armed em: ' + new Date().toLocaleString(),
                    alignment: 'center',
                    fontSize: 8
                },
            ]
        },
        content: [
            {
                text: 'Lista de Usuarios do sistema',
                style: 'cabecalho',
                alignment: 'center',
            },
            {
                table: gararTabela(dados),
            },
        ],
        styles: {
            cabecalho: {
                margin: [0, 10, 0, 20],
                fontSize: 14,
                bold: true,
            }
        },
    };
    const pdfDoc = pdfMake.createPdf(evestosDoc);
    pdfDoc.open();
};



export {
    RelatorioAdministadoresPdf,
    RelatorioRepresentantesPdf,
    SolicitacaoLicenciamentoPdf,
    EstabelecimentosLicenciadospdf,
    RelatorioEmpresasPdf,
    RelatorioDirectoresTecnicosPdf,
    RelatorioGestaoAgendaPdf,
};
