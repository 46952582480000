
import { Container } from "@material-ui/core";
import HeaderSession from "../../../../utils/headerSession";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import Loading from "../../../../load/loading";
import CardInspeccao from "./avfd/cardInspeccao";
import RegisterAccess from "../../../../utils/registerAccess";
import FichaEstabelecimento from "../../../gestLicenciados/fichaEstabelecimento";
import { Card, DialogActions, MenuItem, TextField } from "@mui/material";
import ListaVazia from "../../../../utils/listaVazia";
import InspeccaoRealizada from "./avfd/inspeccaoRealizada";
import FichaDirectorTecnico from "../../../gestDirectorTecnico/fichaDirectorTecnico";

export default function InspeccaoByInspector({ element: component, ...rest }) {
    const user = useSelector(state => state.account.user);
    const [inspeccoes, setInspeccoes] = useState('');
    const [status, setStatus] = useState('Não Realizada');

    useEffect(() => {
        // api.get(`/inspeccaos`)
        setInspeccoes('')
        // api.get(`/inspeccaos?inspectorId=${'666062b53d008b073930f04d'}&status=${status}`)
            api.get(`/inspeccaos?inspectorId=${user._id}&status=${status}`)
            .then(response => {
                // console.log(response)
                setInspeccoes(response.data.inspeccaos);
            }).catch(err => 'console.log(err)')
    }, [user._id, status])

    return (
        <Container style={{ maxWidth: 900, padding: 8 }}>
            <RegisterAccess page={'ver inspeccao por inspector'} />
            <HeaderSession title={'MINHAS INSPECÇÕES'} />

            <DialogActions>
                <TextField
                    style={{ width: 200 }}
                    type="text"
                    placeholder="Categoria"
                    select
                    size="small"
                    variant="outlined"
                    onChange={(e) => { setStatus(e.target.value === 'Geral' ? '' : e.target.value) }}
                    defaultValue={'Não Realizada'}
                >
                    <MenuItem key='Não Realizada' value='Não Realizada'>
                        Não Realizada
                    </MenuItem>
                    <MenuItem key='Realizada' value='Realizada'>
                        Realizada
                    </MenuItem>
                    <MenuItem key='Reprovada' value='Reprovada'>
                        Reprovada
                    </MenuItem>
                    <MenuItem key='Geral' value='Geral'>
                        Geral
                    </MenuItem>
                </TextField>
            </DialogActions>

            {inspeccoes ?
                inspeccoes.map(inspeccao =>
                    inspeccao.status === 'Realizada' ?
                        <InspeccaoRealizada inspeccaoId={inspeccao._id} />
                        :
                        <Card key={inspeccao._id} style={{ marginBottom: 30 }}>
                            <CardInspeccao thisInspeccao={inspeccao} />
                            <FichaEstabelecimento menu={false} estabelecimento={inspeccao?.estabelecimento} />
                            <FichaDirectorTecnico header={true} menu={false} directorTecnico={inspeccao?.estabelecimento.directorTecnico} />
                        </Card>
                )
                :
                <Loading />
            }

            {inspeccoes && inspeccoes.length < 1 && <ListaVazia text={'Nenhuma inspecção encontrada.!'} />}
        </Container>
    )
}