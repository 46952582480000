import { useState } from 'react'
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Paper } from '@mui/material';
import api from '../../../../services/api';
import LoadingShort from '../../../../load/loadingShort';
import MessageError from '../../../../messages/messageError';
import { useNavigate } from 'react-router-dom';

export default function SearchSolicitacaoImportacao() {
    const [loading, setLoading] = useState(false);
    const [solicitacaoId, setSolicitacaoId] = useState('');
    const [messageError, setMessageError] = useState('');
    const navigate = useNavigate()

    const buscarSolicitacao = () => {
        setLoading(true)
        setMessageError('')
        api.get(`/solicitacao-importacao/${solicitacaoId}`)
            .then(response => {
                // console.log(response.data.solicitacaoImportacao)
                navigate(`/utente/solicitacao/importacao/${solicitacaoId}`)
            }).catch(err => {
                // console.log(err)
                setMessageError(err.response.data.message)
            }).finally(() => setLoading(false))
    }

    return (
        <div >

            {messageError && <MessageError message={messageError} />}

            <Paper
                className='solicitacao-status'
                // component="form"
                sx={{ p: '8px 15px', display: 'flex', alignItems: 'center', background: '#85287e' }}
            >
                <InputBase
                    autoFocus
                    sx={{ ml: 1, flex: 1, color: '#fff' }}
                    value={solicitacaoId}
                    onChange={(e) => setSolicitacaoId(e.target.value)}
                    placeholder="Insira o ID da sua solicitação de importação para consultar"
                />
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                <IconButton disabled={solicitacaoId.length < 24} style={{ color: solicitacaoId.length < 24 ? '' : '#fff' }} type="button" sx={{ p: '10px' }} aria-label="search" onClick={buscarSolicitacao} >
                    {loading ? <LoadingShort /> : <SearchIcon />}
                </IconButton>
            </Paper>
        </div>
    )
}
