import React, { useEffect, useState } from 'react';
// import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export default function MessageState() {
    const dispatch =useDispatch()
    const alert = useSelector(state => state.alert.data); 
    const [open, setOpen] = useState(!!alert);

    useEffect(() => {
        setOpen(!!alert)

        setTimeout(() => {
            dispatch({
                type: 'alert',
                payload: { alert: null },
            });
        }, 12000);
        
    }, [alert, dispatch])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="error"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={alert}
            action={action}
        />
    );
}

