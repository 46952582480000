
import { useEffect, useState } from "react";
import api from "../../../../../services/api";
import MessageSuccess from "../../../../../messages/messageSuccess";
import MessageError from "../../../../../messages/messageError";
import LoadingBackdrop from "../../../../../load/loadingBackdrop";
// import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import RegisterAccess from "../../../../../utils/registerAccess";
import HeaderSession from "../../../../../utils/headerSession";
import ButtonLeft from "../../../../../utils/buttonLeft";
import { FormControl, InputLabel, MenuItem, Select, Card, Container, Grid, Typography, TextField } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ItensFactura from "./itensFactura";
import Loading from "../../../../../load/loading";
import * as yup from 'yup'; // VALIDACAO


export default function FormAddItemImportacao() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [addItem, setAddItem] = useState('')
    const [message, setMessage] = useState('')
    const [messageError, setErrorMessage] = useState('')
    const [solicitacao, setSolicitacao] = useState('');
    const [open, setOpen] = useState(false)

    useEffect(() => {
        api.get(`/solicitacao-importacao/${id}`)
            .then(response => {
                // console.log(response)
                if (response.data.solicitacaoImportacao.status !== 'Aprovado') {
                    setSolicitacao(response.data.solicitacaoImportacao)
                } else {
                    navigate(-1)
                }
            }).catch(err => console.log(err))
    }, [id, navigate])

    function handleChange(e) {
        e.target.files ?
            setAddItem({ ...addItem, [e.target.name]: e.target.files[0] })
            :
            setAddItem({ ...addItem, [e.target.name]: e.target.value })
    }

    async function handleAddItem() {
        setOpen(true)
        setErrorMessage('')
        setMessage('')

        api.patch('/solicitacao-importacao/add-item', { ...addItem, solicitacaoImportacaoId: solicitacao?._id })
            .then(res => {
                setMessage(res.data.msg)
                // console.log(res)
                setSolicitacao(res.data.solicitacaoImportacao)
                // setAddItem('')
            }).catch(error => {
                setErrorMessage(error.response.data.message)

                // console.log(error)
            }).finally(() => setOpen(false))
    }

    async function validate() {
        setErrorMessage('');

        let schema = yup.object().shape({

            valorKZ: yup.string("Necessário inserir o valor total em kz").required("Necessário inserir o valor total em kz"),
            valorTotal: yup.string("Necessário inserir o valor total de item").required("Necessário inserir o valor total do item"),
            valorUnitario: yup.string("Necessário inserir o valor unitario de item").required("Necessário inserir o valor unitario do item"),
            pontoEntrada: yup.string("Necessário inserir o ponto de entrada").required("Necessário inserir o ponto de entrada"),
            emolumentosKZ: yup.string("Necessário inserir o valor do emolumento").required("Necessário inserir o valor do emolumento"),
            dataValidade: yup.string("Necessário inserir a data de validade").required("Necessário inserir a data validade"),
            dataEmissao: yup.string("Necessário inserir a data de emissão").required("Necessário inserir a data de emissão"),
            teorBaseG: yup.string("Necessário inserir o teor base (G)").required("Necessário inserir o teor base (G)"),
            quantidade: yup.string("Necessário inserir a quantidade").required("Necessário inserir a quantidade"),
            formaFarmaceutica: yup.string("Necessário inserir a forma farmaceutica").required("Necessário inserir a forma farmaceutica"),
        })

        try {
            await schema.validate(addItem);
            handleAddItem();
        } catch (error) {
            setErrorMessage(error.errors);
        }
        return false
    }

    return (
        <div>

            <RegisterAccess page={'adicionar itens na solicitação de importação'} />
            <LoadingBackdrop open={open} text={'A adcionar item na solicitaçao.!'} />

            <Container >
                <HeaderSession title='ADICIONAR ITENS NA SOLICITAÇÃO DE IMPORTAÇÃO' />
                {message && <MessageSuccess message={message} />}
                {messageError && < MessageError message={messageError} />}


                {solicitacao ?
                    <div>
                        <Card style={{ padding: 10 }}>

                            <Grid container>
                                <Grid xs={12} md item m>
                                    <TextField
                                        fullWidth
                                        type='text'
                                        id="formaFarmaceutica"
                                        name='formaFarmaceutica'
                                        label="Forma farmaceutica"
                                        size="small"
                                        onChange={handleChange}
                                        defaultValue={addItem?.formaFarmaceutica ?? ''}
                                        variant="outlined" />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        id="qtdImport"
                                        name='quantidade'
                                        label="Quantidade a importar"
                                        size="small"
                                        onChange={handleChange}
                                        defaultValue={addItem?.quantidade ?? ''}
                                        variant="outlined" />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <TextField
                                        fullWidth
                                        type='text'
                                        id="teorBaseG"
                                        name='teorBaseG'
                                        label="Teor Base (G)"
                                        size="small"
                                        onChange={handleChange}
                                        defaultValue={addItem?.teorBaseG ?? ''}
                                        variant="outlined" />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid xs={12} md item m>
                                    <TextField
                                        fullWidth
                                        label="Data Emissão"
                                        id='dataEmissao'
                                        name='dataEmissao'
                                        type='date'
                                        size="small"
                                        onChange={handleChange}
                                        defaultValue={addItem?.dataEmissao ?? ''}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <TextField
                                        fullWidth
                                        label="Data Validade"
                                        id='dataValidade'
                                        name='dataValidade'
                                        type='date'
                                        size="small"
                                        onChange={handleChange}
                                        defaultValue={addItem?.dataValidade ?? ''}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <TextField
                                        fullWidth
                                        type='text'
                                        id="emolumentosKz"
                                        name='emolumentosKZ'
                                        label="Emolumentos kz"
                                        size="small"
                                        onChange={handleChange}
                                        defaultValue={addItem?.emolumentosKZ ?? ''}
                                        variant="outlined" />
                                </Grid>
                            </Grid>

                            <Grid container>

                                <Grid xs={12} md item m>

                                    <FormControl fullWidth>
                                        <InputLabel id="pontoEntrada-select-label">Ponto de entrada</InputLabel>
                                        <Select
                                            labelId="pontoEntrada-select-label"
                                            id="pontoEntrada-select"
                                            label="Ponto de entrada"
                                            name='pontoEntrada'
                                            size="small"
                                            onChange={handleChange}
                                            defaultValue={addItem?.pontoEntrada ?? ''}
                                        >
                                            <MenuItem value="" disabled>...</MenuItem>
                                            <MenuItem value="Porto">Porto</MenuItem>
                                            <MenuItem value="Aeroporto">Aeroporto</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid xs={12} md item m>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Moeda</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Moeda"
                                            name='moeda'
                                            size="small"
                                            onChange={handleChange}
                                            defaultValue={addItem?.moedas ?? ''}
                                        >
                                            <MenuItem value="" disabled>...</MenuItem>
                                            <MenuItem value="USD">USD</MenuItem>
                                            <MenuItem value="EUR">EUR</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid xs={12} md item m>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        id="valorUnit"
                                        name='valorUnitario'
                                        label="Valor unitario"
                                        size="small"
                                        onChange={handleChange}
                                        defaultValue={addItem?.valorUnitario ?? ''}
                                        variant="outlined" />
                                </Grid>
                            </Grid>


                            <Grid container>

                                <Grid xs={12} md item m>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Valor total"
                                        id='valorTotal'
                                        name='valorTotal'
                                        size="small"
                                        onClick={handleChange}
                                        defaultValue={addItem?.valorTotal ?? ''}
                                    />
                                </Grid>

                                <Grid xs={12} md item m>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Valor total em Kz"
                                        id='valorKz'
                                        name='valorKZ'
                                        size="small"
                                        onClick={handleChange}
                                        defaultValue={addItem?.valorKZ ?? ''}
                                    />
                                </Grid>

                                <Grid xs={12} md item m />

                            </Grid>

                            <Typography marginTop={5} align="center" variant="subtitle2" >
                                Adiciona cada item da factura.
                            </Typography>

                            <br />

                            <ButtonLeft title={'Adicionar'} funcao={validate} disabled={!addItem} >
                                <Link style={{ textDecoration: 'none', padding: 10 }} to={`/utente/solicitacao/importacao/${solicitacao._id}`}>CONCLUIDO</Link>
                            </ButtonLeft>
                        </Card>

                        <br />
                        {/* overflow={'auto'} */}
                        <ItensFactura itens={solicitacao?.items} solicitacao={solicitacao} />

                    </div>
                    :
                    <Loading />
                }

            </Container>
        </div>
    );
}
