import axios from 'axios';
const accessToken = localStorage.getItem("accessToken");
const tokenDefault = process.env.REACT_APP_TOKEN_DEFAULT;

export default axios.create({
    // baseURL: process.env.REACT_APP_API_URL,
    baseURL: process.env.REACT_APP_API_URL_SUPORT,
    // baseURL: 'http://localhost:8080/',
    headers: { Authorization: 'Bearer '.concat(accessToken ?? tokenDefault) }
});
