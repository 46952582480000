import { Avatar, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import Loading from "../../load/loading";
import { makeStyles } from "@material-ui/core";
import FerramentaNavegacaoExportacao from "../../utils/ferramentaNavegacaoExportacao";
import SearshDirectorTecnico from "./searchDirectorTecnico";
import RegisterAccess from "../../utils/registerAccess";
import ListaVazia from "../../utils/listaVazia";
import HeaderSession from "../../utils/headerSession";
import { RelatorioDirectoresTecnicosPdf } from "../exports/gerarelatoriopdf";
import { RelatorioDirectoresTecnicosExcel } from "../exports/gerarelatorioexcel";


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#d2d6ff' }, cursor: 'pointer' }
}))

export default function DirectoresTecnicos() {
    const classes = useStyles()
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState('')
    const [directorTecnicos, setDirectorTecnicos] = useState(useSelector(state => state?.directoresTecnicos?.data) ?? '')
    const [configLista, setConfigLista] = useState(useSelector(state => state?.directoresTecnicos?.config) ?? '')
    const [page, setPage] = useState(useSelector(state => state?.directoresTecnicos.data !== null ? state?.directoresTecnicos?.config?.previousPage + 1 : 1));
    const dispatch = useDispatch();
    const [perPage, setPerPage] = useState(20)
    const [order, setOrder] = useState('')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        api.get(`/director-tecnicos?page=${page}&perPage=${perPage}&sortBy=nome:${order}`)
            .then(response => {
                // console.log(response)
                setConfigLista(response.data);
                setDirectorTecnicos((prevItems) => [...prevItems, ...response.data.directorTecnicos]);
                // setFilter(response.data.directorTecnicos);

                setLoading(false)
            }).catch(err => { setLoading(false) })

        dispatch({
            type: 'directoresTecnicos',
            payload: { directoresTecnicos: directorTecnicos, config: configLista },
        }); //dispatch para por os directorTecnicos no state da aplicacao e evitar recarregar desnecessariamente

    }, [page, perPage, order]);


    return (
        <>
            <RegisterAccess page={'lista de directores técnicos registados'} />

            <HeaderSession
                title='DIRECTORES TÉCNICOS REGISTADOS'
                actions={true}
                handleExportExcel={() => RelatorioDirectoresTecnicosExcel(directorTecnicos)}
                handleExportPDF={() => RelatorioDirectoresTecnicosPdf(directorTecnicos)}
            />

            {directorTecnicos &&

                <Card style={{ padding: 20, minHeight: '20vh', }}>

                    <SearshDirectorTecnico setDirectorTecnico={setDirectorTecnicos} setPesquisaError={setErrorMessage} />

                    <Card variant="outlined">
                        <TableContainer style={{ overflowX: 'auto' }} sx={{ width: '100%', overflow: 'hidden' }}>
                            <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell><Typography noWrap> <strong>Foto</strong></Typography></TableCell>
                                        <TableCell><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                                        <TableCell><Typography noWrap> <strong>Carteira</strong></Typography></TableCell>
                                        <TableCell><Typography noWrap> <strong>Categoria</strong></Typography></TableCell>
                                        <TableCell><Typography noWrap> <strong>Telefone</strong></Typography></TableCell>
                                        <TableCell><Typography noWrap> <strong>Provincia</strong></Typography></TableCell>
                                        <TableCell><Typography noWrap> <strong>Municipio</strong></Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {directorTecnicos.map((directorTecnico) => (
                                        <TableRow key={directorTecnico.dadosPessoais?._id} onClick={() => navigate(`/admin/get-director-tecnico/${directorTecnico._id}`)} className={classes.hover}>

                                            <TableCell>
                                                <Avatar src={directorTecnico.dadosPessoais?.fotoURL} />
                                            </TableCell>

                                            <TableCell>{directorTecnico.dadosPessoais?.nome}</TableCell>
                                            <TableCell>{directorTecnico.numeroCarteira}</TableCell>
                                            <TableCell>{directorTecnico?.categoria?.designacao}</TableCell>
                                            <TableCell> <Typography noWrap> {directorTecnico.dadosPessoais?.tel1}</Typography></TableCell>
                                            <TableCell> <Typography noWrap> {directorTecnico.dadosPessoais?.provincia}</Typography></TableCell>
                                            <TableCell> <Typography noWrap> {directorTecnico.dadosPessoais?.municipio}</Typography></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            {errorMessage && <ListaVazia text={errorMessage} />}

                        </TableContainer>
                    </Card>

                    <FerramentaNavegacaoExportacao
                        configLista={configLista}
                        lista={directorTecnicos}
                        page={page}
                        setPage={setPage}
                        setPerPage={setPerPage}
                        setOrder={setOrder}
                    // exportarExcel={exportarExcel}
                    />
                </Card>
            }

            {loading && <Loading />}
        </>
    )
}