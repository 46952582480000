
import { useEffect, useState } from 'react';
import { Avatar, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, Card, Typography, makeStyles } from '@material-ui/core';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import HeaderSession from "../../utils/headerSession";
import Loading from "../../load/loading";
import FerramentaNavegacaoExportacao from "../../utils/ferramentaNavegacaoExportacao";
import { useDispatch, useSelector } from 'react-redux';
import SearshEstabelecimento from '../../utils/searchEstabelecimento';
import StatusEstabelecimento from '../../utils/statusEstabelecimento';
import RegisterAccess from '../../utils/registerAccess';
import { EstabelecimentosLicenciadosexcel } from '../exports/gerarelatorioexcel';
import { EstabelecimentosLicenciadospdf } from '../exports/gerarelatoriopdf';
// import ListaVazia from "../../utils/ListaVazia";


const useStyles = makeStyles((theme) => ({
  hover: { '&:hover': { backgroundColor: '#d2d6ff' }, cursor: 'pointer' }
}))

export default function EstabelecimentosLicenciados() {
  const classes = useStyles()
  const navigate = useNavigate()
  const [filter, setFilter] = useState(useSelector(state => state?.estabelecimentoLicenciados?.data) ?? '')
  const [estabelecimentos, setEstabelecimentos] = useState(useSelector(state => state?.estabelecimentoLicenciados?.data) ?? '')
  const [configLista, setConfigLista] = useState(useSelector(state => state?.estabelecimentoLicenciados?.config) ?? '')
  // const [page, setPage] = useState(useSelector(state => state?.estabelecimentoLicenciados.data !== null ? state?.estabelecimentoLicenciados?.config?.previousPage + 1 : 1));
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  // const feedRef = useRef(null);
  const [perPage, setPerPage] = useState(20)
  const [order, setOrder] = useState('')
  const [loading, setLoading] = useState(false);
  // console.log(page)

  useEffect(() => {
    setLoading(true)
    api.get(`/estabelecimentos?page=${page}&status=Activo&perPage=${perPage}&sortBy=nome:${order}`)
      .then(response => {
        // console.log(response)
        // setEstabelecimentos(response.data.estabelecimentos);
        setConfigLista(response.data);
        setEstabelecimentos((prevItems) => [...prevItems, ...response.data.estabelecimentos]);
        setFilter(response.data.estabelecimentos);

        setLoading(false)
      }).catch(err => { setLoading(false) })

    dispatch({
      type: 'estabelecimentoLicenciados',
      payload: { estabelecimentoLicenciados: estabelecimentos, config: configLista },
    }); //dispatch para por os estabelecimentos no state da aplicacao e evitar recarregar desnecessariamente

  }, [page, perPage, order]);


  const filtraEstabelecimento = async (valor) => {
    setEstabelecimentos(filter.filter((estabelecimento) => (
      estabelecimento?.nome?.toLowerCase().includes(valor.toLowerCase())
      || estabelecimento?.numeroProcesso?.toLowerCase().includes(valor.toLowerCase())
      || estabelecimento?.tipo?.designacao?.toLowerCase().includes(valor.toLowerCase())
    )))
  }

  return (
    <>
      <RegisterAccess page={'lista de estabelecimentos licenciados'} />

      <HeaderSession
        title='ESTABELECIMENTOS LICENCIADOS'
        actions={true}
        handleExportExcel={() => EstabelecimentosLicenciadosexcel(estabelecimentos)}
        handleExportPDF={() => EstabelecimentosLicenciadospdf(estabelecimentos)}
      />


      <Card style={{ padding: 20 }}>
        <SearshEstabelecimento setEstabelecimentos={setEstabelecimentos} functionFilter={filtraEstabelecimento} placeholder={'Filtra por nome, número de processo..'} />

        {estabelecimentos &&
          <Card variant="outlined">

            <TableContainer >
              <Table id="myTable" size="small" stickyHeader aria-label="sticky table">
                <TableHead >
                  <TableRow>
                    <TableCell ><Typography noWrap> <strong>Imagem</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Tipo</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Número de Processo</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Director Técnico</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Provincia</strong></Typography></TableCell>
                    <TableCell ><Typography noWrap> <strong>Estado</strong></Typography></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {estabelecimentos.map((estabelecimento) => (
                    <TableRow key={estabelecimento._id} className={classes.hover} onClick={() => navigate(`/admin/gestor-licenciados/${estabelecimento?._id}`)} title={estabelecimento?.nome}>

                      <TableCell component="th" scope="row">
                        <Avatar variant='square' src={estabelecimento?.logo} alt={estabelecimento?.nome} />
                      </TableCell>

                      <TableCell >{estabelecimento?.nome}</TableCell>
                      <TableCell >{estabelecimento?.tipo?.designacao}</TableCell>
                      <TableCell >{estabelecimento?.numeroProcesso}</TableCell>
                      <TableCell >{estabelecimento?.directorTecnico?.dadosPessoais?.nome}</TableCell>
                      <TableCell >{estabelecimento?.provincia}</TableCell>
                      <TableCell >
                        <StatusEstabelecimento status={estabelecimento?.status} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <FerramentaNavegacaoExportacao
              configLista={configLista}
              lista={estabelecimentos}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              setOrder={setOrder}
            // exportarExcel={exportarExcel}
            />
          </Card>
        }

        {loading &&
          <div >
            <Loading />
          </div>
        }
      </Card>
    </>
  )
}
