import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';

function Editor({ setEditorData, editorData, }) {
    // console.log(editorData)

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData((prev) => ({ ...prev, conteudo: data }));
        // setEditorData({ ...editorData, 'descricao': data })
    };


    return (
        <div>
            {/* <button onClick={handlePrint}>print</button> */}
            <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onChange={handleEditorChange}
            />
            {/* <h2>Conteúdo do Editor:</h2>
            <div dangerouslySetInnerHTML={{ __html: editorData }} /> */}
        </div>
    );
}

export default Editor;


