
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from "@mui/material";
import ImageLink from './imageLink';
// import classes from "./services.module.css"
// import classes from './imageLink';
// import Styles from './ImageLinks.module.css';

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '60vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    root: {
        // marginTop: -40,
        minHeight: '60vh',
        backgroundImage: 'url(/img/backGrounds/MapaMundo.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.5,
        position: 'absolute',
        width: '100%',
        zIndex: -1,
    },

    containerCardesService: {
        // padding: '2rem',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1.5rem',	
        /* ALINHAR OS ITENS NA HORIZONTAL E NA VERTIVACL */
        justifyContent: 'center',

    }
}))

export default function Links() {
    const classes = useStyles();

    return (

        <div className={classes.container}>
            <div className={classes.root} />
            
            <div>
                <Typography align="center" variant="h1" style={{ fontSize: 25, fontWeight: 'bold', marginBottom:40 }}>
                    LINKS
                </Typography>

                <Box className={classes.containerCardesService}>
                    <ImageLink src={'/img/linksUteis/oms.png'} link='https://www.who.int/pt1' />
                    <ImageLink src={'/img/linksUteis/thewordbank.png'} link='https://www.worldbank.org/pt/country/angola' />
                    <ImageLink src={'/img/linksUteis/usaid.png'} link='https://www.usaid.gov/ ' />
                    <ImageLink src={'/img/linksUteis/pnud.png'} link='https://www.pnud.org/pt/angola' />
                    <ImageLink src={'/img/linksUteis/ofa.svg'} link='https://web.facebook.com/OrdemFarmaceuticosAngola/?_rdc=1&_rdr' />
                    <ImageLink src={'/img/linksUteis/of.png'} link='https://www.ordemfarmaceuticos.pt/pt/' />
                    <ImageLink src={'/img/linksUteis/anvisa.png'} link='https://www.gov.br/anvisa/pt-br' />
                    <ImageLink src={'/img/linksUteis/infarmed.png'} link='https://www.infarmed.pt/' />
                    <ImageLink src={'/img/linksUteis/inis.png'} link='https://inis.gov.ao/' />
                </Box>
            </div>
        </div>
    )
}