/// <reference no-default-lib="true"/>
/* eslint-disable */
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Card, Container, IconButton } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import api from "../../../services/api";
import Date from "../../../utils/date";
import HeaderSession from "../../../utils/headerSession";
import MessageSuccess from "../../../messages/messageSuccess";
import MessageError from "../../../messages/messageError";
import Createinformacao from "./createInformacao";
import Loading from "../../../load/loading";
import { makeStyles } from '@material-ui/core'
import ButtonLeft from "../../../utils/buttonLeft";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 20,
        textAlign: 'justify',
        fontSize: 20,
        lineHeight: 1.5
    },
    title: {
        fontSize: 25,
        fontWeight: 'bold',
        paddingTop: 50,
        // textAlign: 'center',
        color: '#67348d'
    },
}))

export default function GestSite() {
    const classes = useStyles()
    const [siteInfos, setSiteInfos] = useState('')
    const [createInformacao, setCreateInformacao] = useState(false)
    const [editeInformacao, setEditeInformacao] = useState(false)
    const [message, setMessage] = useState('')
    const [messageError, seterrorMessage] = useState('')

    useEffect(() => {
        api.get(`/site-infos?perPage=200`)
            .then(response => {
                setSiteInfos(response.data.siteInfos);
            }).catch(error => {

            })
    }, [createInformacao, editeInformacao])

    const HandleEdit = (informacao) => {
        setEditeInformacao(informacao)
    }

    return (
        <Container>
            <HeaderSession title={'GESTÃO DO SITE'} />

            {message && <MessageSuccess message={message} />}
            {messageError && <MessageError message={messageError} />}

            <Card style={{ padding: 50 }}>
                {(!createInformacao && !editeInformacao) && <ButtonLeft title={'criar'} funcao={() => setCreateInformacao(!createInformacao)} />}

                {createInformacao &&
                    <Createinformacao
                        informacao={''}
                        setCreateInformacao={setCreateInformacao}
                        setMessage={setMessage}
                        seterrorMessage={seterrorMessage}
                    />
                }

                {editeInformacao &&
                    <Createinformacao
                        informacao={editeInformacao}
                        setCreateInformacao={setEditeInformacao}
                        setMessage={setMessage}
                        seterrorMessage={seterrorMessage}
                    />
                }

                {(siteInfos && !createInformacao && !editeInformacao) &&

                    siteInfos.map((info) => (
                        <div key={info._id} informacao>

                            <h1 className={classes.title}>
                                {/* O que é um ensaio/ estudo clínico? */}
                                {info?.menu + ' / '} {info?.titulo}
                                <IconButton title='Editar' onClick={() => HandleEdit(info)} color="error"> <ModeEditIcon color='secondary' /></IconButton>
                            </h1>

                            <Typography variant="subtitle2" style={{ lineHeight: 1, fontSize: 10 }}>
                                Data: <Date date={info.createdAt} />
                                <br />
                                Ultima actualização: <Date date={info.updatedAt} />
                            </Typography>

                            <div className={classes.root} style={{ lineHeight: 1.5 }} dangerouslySetInnerHTML={{ __html: info?.conteudo }} />

                            {info?.anexo && (
                                info?.anexo.includes("png") ?
                                    <img width={800} style={{ width: '100%', objectFit: 'cover', }} src={info?.anexo} alt="" />
                                    :
                                    <a href={info?.anexo} target={'_blank'} rel="noreferrer">Ver anexo </a>
                            )}
                        </div>
                    ))}

                {!siteInfos && <Loading text={'A carregar informações do site'} />}
            </Card>
        </Container>
    )
}