import { Box, Card, MenuItem } from "@mui/material";
import { Divider } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import StatusEstabelecimento from "../../utils/statusEstabelecimento";
import ImagemEdit from "../../utils/imagemEdit";

export default function NavLateral({ estabelecimento, thisId }) {
    // thisId é passado como props quando este componente vai precisar de um id esquecifico en veis de captuar do useParams
    const url = useLocation();
    const { id } = useParams() 
    const navigate = useNavigate()

    return (
        <Card style={{ padding: 10 }}>

            <ImagemEdit
                data={estabelecimento}
                url={'/estabelecimento/change-logo'}
                local={'estabelecimentoId'}
                openEditLogo={false}
            // setOpenEditLogo={setOpenEditLogo}
            />

            <p style={{ fontSize: 14, textAlign: 'center' }}>
                ESTABELECIMENTO LICENCIADO   <img src="/img/icones/licenciamentoicon.svg" alt="" width={20} />
            </p>
            <StatusEstabelecimento status={estabelecimento.status} />
            <Divider />

            <Box marginTop={2}>
                <MenuItem selected={url.pathname === `/admin/gestor-licenciados/${thisId ?? id}`} onClick={() => { navigate(`/admin/gestor-licenciados/${thisId ?? id}`) }} >Informções Gerais </MenuItem>
                <MenuItem selected={url.pathname === `/admin/autorizacao-de-exercicio/${thisId ?? id}`} onClick={() => { navigate(`/admin/autorizacao-de-exercicio/${thisId ?? id}`) }} >Autorização de Exercício </MenuItem>
                <MenuItem selected={url.pathname === `/admin/gestor-licenciados/inspecao/${thisId ?? id}`} onClick={() => { navigate(`/admin/gestor-licenciados/inspecao/${thisId ?? id}`) }} > Inspeções </MenuItem>
                <MenuItem selected={url.pathname === `/admin/gestor-licenciados/observacoes/${thisId ?? id}`} onClick={() => { navigate(`/admin/gestor-licenciados/observacoes/${thisId ?? id}`) }} > Observações </MenuItem>
                {/* <MenuItem selected={url.pathname === `/admin/gestor-licenciados/responsavel-pelo-registo/${id}`} onClick={() => { navigate(`/admin/gestor-licenciados/responsavel-pelo-registo/${id}`) }} > Responsável pelo Registo </MenuItem> */}
            </Box>
        </Card>
    )
}