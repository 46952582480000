
import { useEffect } from 'react'
import { useState } from "react";
import api from '../services/api';
import LoadingShort from '../load/loadingShort';
import { Button } from '@mui/material';


export default function GetDoc({ id }) {
    const [load, setLoad] = useState(false)
    const [doc, setDoc] = useState('')

    useEffect(() => {
        setLoad(true);
        api.get(`/doc-upload/${id}`)
            .then(res => {
                setDoc(res.data.docUpload);
            })
            .catch((err) => {
                // console.log(err)
            }).finally(() => {
                setLoad(false);
            });
    }, [id])


    return (
        load ?
            <LoadingShort />
            :
            <Button style={{ background: '#85287e', margin: 10 }} >
                <img src='/img/icones/downloadicon.svg' alt="" />
                <a style={{ textDecoration: 'none', color: '#fff' }} href={doc?.anexo} target="_blank">
                    Baixar Manual
                </a>
            </Button>
    )
}

