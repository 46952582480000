
import {
    Button, MenuItem, Paper, TextField, Typography, FormControl,
    RadioGroup, FormLabel, FormControlLabel, Radio
} from '@mui/material';
import { useState } from 'react';
import api from '../../../services/api';
import LoadingBackdrop from "../../../load/loadingBackdrop";
import { useSelector } from 'react-redux';
import ImageNoticiaEdit from './imageNoticiaEdit';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import Editor from '../../../ckeditor/editor';
import ViewNoticia from '../../../pages/home/noticias/viewNoticia';
// import RegisterAccess from '../../../utils/registerAccess';

const CreateNoticia = (props) => {
    const [noticia, setNoticia] = useState(props?.noticia)
    const [open, setOpen] = useState(false)
    const user = useSelector(state => state.account.user);
    const [Preview, setPreview] = useState(false)

    // console.log(noticia)

    function HandleChange(e) {
        e.target.files ?
            setNoticia({ ...noticia, [e.target.name]: e.target.files[0] })
            :
            setNoticia({ ...noticia, [e.target.name]: e.target.value })
    }

    async function HandleCreateNoticia() {
        const formData = new FormData();
        formData.append('userId', user._id);
        formData.append('titulo', noticia?.titulo ?? '');
        formData.append('subTitulo', noticia?.subTitulo ?? '');
        formData.append('imagem', noticia?.imagem);
        formData.append('anexo', noticia?.anexo);
        formData.append('categoria', noticia?.categoria ?? '');
        formData.append('fonte', noticia?.fonte ?? '');
        formData.append('link', noticia?.link ?? '');
        formData.append('conteudo', noticia?.conteudo ?? '');
        formData.append('seccao', noticia?.seccao);
        formData.append('destaque', true);
        formData.append('status', 'Activo');

        setOpen(true)
        props.seterrorMessage('')
        props.setMessage('')

        props?.noticia ?
            // EDITANDO UMA NOTICIA
            await api.put('/noticia/edit', { ...noticia, 'userId': user._id, 'noticiaId': props.noticia._id })
                .then(res => {
                    setOpen(false)
                    // console.log(res)
                    props.setMessage(res.data.msg)
                    props.setEditarNoticia(false)
                }).catch(error => {

                    // props.seterrorMessage(error.response.data.message)
                    setOpen(false)
                    // console.log(error)
                })
            :
            // CRIANDO UMA NOVA NOTICIA 
            await api.post('/noticia/create', formData)
                .then(res => {
                    setOpen(false)
                    // console.log(res)
                    props.setMessage(res.data.msg)
                    props.setCreateNoticia(false)
                }).catch(error => {

                    props.seterrorMessage(error.response.data.message)
                    setOpen(false)
                    // console.log(error)
                })
    }


    return (
        <>
            {/* <RegisterAccess page={'Criar noticia'}/> */}
            <LoadingBackdrop open={open} text={'A criar notícia. Aguarde!'} />

            {!Preview ?
                <>
                    <div>
                        <Typography align='center' variant="subtitle1" style={{ fontSize: 20 }}>
                            {props.title}
                        </Typography>
                        <br />

                        <FormControl>
                            <RadioGroup
                                row
                                style={{ alignItems: 'center', }}
                            >
                                <FormLabel style={{ marginRight: 10 }} id="destaque">Tipo de publicação:</FormLabel>
                                <FormControlLabel name="seccao" value={'Notícia'} control={<Radio />} label="Notícia" onClick={HandleChange} />
                                <FormControlLabel name="seccao" value={'Destaque'} control={<Radio />} label="Destaque" onClick={HandleChange} />
                            </RadioGroup>
                        </FormControl>

                        {noticia?.seccao === 'Destaque' &&
                            <div >
                                <label>Anexar despacho</label> <br />
                                <input accept="application/pdf" type="file" name="anexo" id="ficheiros2" onChange={HandleChange} />
                            </div>
                        }
                        <br />
                        <br />

                        {props?.noticia
                            ?
                            <ImageNoticiaEdit foto={props.noticia.imagem} noticiaId={props.noticia._id} setEditarNoticia={props.setEditarNoticia} />
                            :
                            <Paper style={{ border: '1px dashed #3e3d3f' }}>
                                <label htmlFor="imagem" style={{ cursor: 'pointer', textAlign: 'center' }}>
                                    {noticia?.imagem ?

                                        <img src={props?.noticia ? props.noticia.imagem : URL.createObjectURL(noticia.imagem)} alt="Imagem" width="100%" title="alterar imagem.." />
                                        :
                                        <Typography align="center" variant='body2' style={{ padding: 20 }}>
                                            Imagem: 1000x650px - Resolução: 72px
                                        </Typography>
                                    }
                                    <input accept="image/png, image/jpg, image/jpeg" type="file" name="imagem" id="imagem" style={{ display: 'none' }} onChange={HandleChange} />
                                </label>
                            </Paper>
                        }

                        <TextField
                            margin='dense'
                            type="text"
                            required
                            label="Titulo"
                            fullWidth
                            size="small"
                            name="titulo"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={noticia?.titulo}
                        />

                        <TextField
                            margin='dense'
                            type="text"
                            label="Subtitulo"
                            fullWidth
                            size="small"
                            name="subTitulo"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={noticia?.subTitulo}
                        />

                        <TextField
                            margin='dense'
                            type="text"
                            label="Categoria"
                            select
                            fullWidth
                            size="small"
                            name="categoria"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={noticia?.categoria || props.noticia?.categoria || ''}
                        >
                            <MenuItem key="A" value="ANGOLA">
                                ANGOLA
                            </MenuItem>
                            <MenuItem key="armed" value="ARMED">
                                ARMED
                            </MenuItem>
                            <MenuItem key="oms" value="OMS">
                                OMS
                            </MenuItem>
                            <MenuItem key="s" value="SOCIEDADE">
                                SOCIEDADE
                            </MenuItem>
                            <MenuItem key="c" value="DESPORTO">
                                DESPORTO
                            </MenuItem>
                            <MenuItem key="d" value="POLÍTICA">
                                POLÍTICA
                            </MenuItem>
                            <MenuItem key="M" value="MUNDO">
                                MUNDO
                            </MenuItem>
                            <MenuItem key="r" value="REPORTAGEM">
                                REPORTAGEM
                            </MenuItem>
                            <MenuItem key="o" value="OUTROS">
                                OUTROS
                            </MenuItem>
                        </TextField>

                        <TextField
                            margin='dense'
                            type="text"
                            label="Fonte da notícia"
                            fullWidth
                            size="small"
                            name="fonte"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={noticia?.fonte}
                        />

                        <TextField
                            margin='dense'
                            type="url"
                            label="Link da fonte"
                            fullWidth
                            size="small"
                            name="link"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={noticia?.link}
                        />
                        <br />
                    </div>

                    {/* EDITOR DE TEXTO CKEditor*/}
                    <Editor
                        setEditorData={setNoticia}
                        editorData={noticia?.conteudo}
                    />

                    <div align='right'>
                        {/* FORM PARA PARA DESCATAR OU NAO A NOTICIA */}
                        {/* <FormControl>
                            <RadioGroup
                                row
                                style={{ alignItems: 'center' }}
                            >
                                <FormLabel style={{ marginRight: 10 }} id="destaque">Destacar </FormLabel>
                                <FormControlLabel name="destaque" value={false} control={<Radio />} label="Não" onClick={HandleChange} />
                                <FormControlLabel name="destaque" value={true} control={<Radio />} label="Sim" onClick={HandleChange} />
                            </RadioGroup>
                        </FormControl> */}

                        {/* FORM PARA APLICAR STATUS DE PUBLICAR OU GUARDAR A NOTICIA */}
                        <br />
                        <FormControl>
                            <RadioGroup
                                row
                                style={{ alignItems: 'center' }}
                            >
                                <FormLabel style={{ marginRight: 10 }} id="status">Status </FormLabel>
                                <FormControlLabel name="status" value={'Activo'} control={<Radio />} label="Publicar" onClick={HandleChange} />
                                <FormControlLabel name="status" value={'Inactivo'} control={<Radio />} label="Não Publicar" onClick={HandleChange} />
                            </RadioGroup>
                        </FormControl>
                        <br />
                        <br />
                        <Button color='error' style={{ marginRight: 10 }}  onClick={() => { props?.noticia ? props.setEditarNoticia(false) : props.setCreateNoticia(false); props.setTitle('Criação') }}>
                            Cancelar
                        </Button>
                        <Button style={{ marginRight: 10 }} variant="contained" onClick={() => setPreview(true)}>
                            Preview
                        </Button>
                        <Button disabled={(!noticia?.imagem || !noticia?.seccao)} style={{ backgroundColor: '#85287e' }} variant="contained" onClick={HandleCreateNoticia}>
                            Salvar
                        </Button>
                    </div>
                </>

                :
                //  PREVIEW 
                <div >
                    <Typography variant="subtitle1" style={{ fontSize: 20, margin: 5 }}>
                        <IconButton size='small' color='error' style={{ marginRight: 10 }} onClick={() => setPreview(false)}>
                            <CloseIcon />
                        </IconButton>
                        Preview
                    </Typography>
                    {/* <Divider /> */}

                    <ViewNoticia preview={noticia} criacao={true} />
                </div>
            }
        </>
    );
}

export default CreateNoticia;
