import { Button, DialogActions } from '@mui/material'
import LoadingShort from '../load/loadingShort'

export default function ButtonLeft({ title, funcao, disabled, load, children }) {
    return (
        <DialogActions>
            {children && children}
            <Button disabled={disabled} style={{ backgroundColor: '#85287e' }} variant="contained" onClick={funcao}>{load ? <LoadingShort /> : title}</Button>
        </DialogActions>
    )
}
