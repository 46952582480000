import { Typography } from "@material-ui/core";
import HeaderGestorConfigs from "../../utils/headerGestorConfigs";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { Alert, AlertTitle, Button, Card, DialogActions, IconButton, Tooltip } from "@mui/material";
import LoadingBackdrop from "../../load/loadingBackdrop";
import Loading from "../../load/loading";
import DateHora from "../../utils/dateHora";
import TempoPassado from "../../utils/tempoPassado";
import FichaEstabelecimento from "./fichaEstabelecimento";
// import MessageSuccess from "../../messages/messageSuccess";
// import RegisterAccess from "../../utils/registerAccess";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { AutorizacaoPDF } from "./exports/autorizacao";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function AutorizacoesExercicioEmitidas({ estabelecimento, geral }) {
    const [load, setLoad] = useState(false);
    // const [openReenviarEmail, setOpenReenviarEmail] = useState(false);
    const [autorizacoes, setAutorizacoes] = useState('');
    // const [messageSuccess, setMessageSuccess] = useState('');
    // const [thisEstabelecimento, setThisEstabelecimento] = useState('');

    useEffect(() => {
        setLoad(true)

        api.get(`/autorizacaos?estabelecimentoId=${estabelecimento?._id ?? ''}&perPage=200`)
            .then(response => {
                // console.log(response)
                setAutorizacoes(response.data.autorizacaos);
                // setConfigLista(response.data);
                setLoad(false)
            }).catch(err => { setLoad(false) })
    }, [estabelecimento?._id]);


    // const abrirPdfnoBrowser = async (dado) => {
    //     const pdfGerarAquivo = await AutorizacaoPDF(dado);
    //     pdfGerarAquivo.open(); 
    // };

    // const baixaPdf = async (dado) => {
    //     const pdfGerarAquivo = await AutorizacaoPDF(dado);
    //     pdfGerarAquivo.download(`Autorização - ${dado?.estabelecimento?.nome}`);
    // };

    const imprimirPdf = async (dado) => {
        const pdfGerarAquivo = await AutorizacaoPDF(dado);
        pdfGerarAquivo.print();
    };

    // const verMeuDocumento = async (dado) => {
    //     const pdfGerarAquivo = await AutorizacaoPDF(dado);
    //     pdfGerarAquivo.getDataUrl((dadosUrl) => {
    //         const targetElement = document.querySelector('#iframeContainer');
    //         const iframe = document.createElement('iframe');
    //         iframe.width = '100%';
    //         iframe.height = '100%';
    //         iframe.src = dadosUrl;
    //         targetElement.appendChild(iframe);
    //     });
    // }

    return (
        autorizacoes
            ?
            <>
                {autorizacoes.map((autorizacao) =>
                    <Card key={autorizacao._id} style={{ padding: 20, marginBottom: 10 }}>
                        <LoadingBackdrop open={load} text={load} />
                        {/* {messageSuccess && <MessageSuccess message={messageSuccess} />} */}
                        {/* {messageError && <MessageError message={messageError} />} */}

                        <HeaderGestorConfigs
                            menu={true}
                            autorizacao={autorizacao}
                            generatePDF={imprimirPdf}
                            // setOpenReenviarEmail={setOpenReenviarEmail}
                            configArea={'autorizacaoExercio'}
                        // title={geral? :''}
                        />

                        <div style={{ padding: 10, position: 'relative' }}>
                            <IconButton size="large" onClick={() => imprimirPdf(autorizacao)} style={{ position: 'absolute', right: 0 }}>
                                <img src="/img/icones/licenciamentoicon.svg" alt="autor.." width={50} />
                            </IconButton>

                            <Typography>Nº de Autorização: <strong>{autorizacao?.estabelecimento?.numeroAutorizacao}</strong></Typography>
                            <Typography>Data de Emissão: <strong><DateHora date={autorizacao?.dataEmissao} /></strong> </Typography>
                            <Typography>Data de Expiração: <strong><DateHora date={autorizacao?.dataExpiracao} /></strong> </Typography>
                            <Typography>Emitido por: <strong>{autorizacao?.por?.dadosPessoais?.nome}</strong> </Typography>
                            <Typography>Tempo de actividade termina: <strong><TempoPassado date={autorizacao?.dataExpiracao} /></strong> </Typography>
                            <Typography>Director(a) Geral em exercício: <strong>{autorizacao?.directorGeral?.dadosPessoais?.nome}</strong> </Typography>
                        </div>

                        {geral && <FichaEstabelecimento menu={false} estabelecimento={autorizacao?.estabelecimento} />}

                        <DialogActions>
                            <Tooltip title="Clique para abrir">
                                <Button onClick={() => imprimirPdf(autorizacao)} style={{ background: '#85287e' }} variant="contained">ver</Button>
                            </Tooltip>
                        </DialogActions>
                    </Card>
                )
                }
                {autorizacoes.length < 1 &&
                    <Alert severity="warning">
                        <AlertTitle>Para este estabelecimento ainda não foi emitida nenhuma Autorização de Exercício pelo sistema.</AlertTitle>
                    </Alert>
                }
            </>
            :
            <Loading />
    )
}