import { useEffect, useState } from 'react'
import api from '../../../../services/api';
import ReciboRenovacao from '../renovacao/utente/reciboRenovacao';
import { Container, Typography } from '@mui/material';
import Loading from '../../../../load/loading';
import { useNavigate, useParams } from 'react-router-dom';
import Recibo from './cadastroEstabelecimento/recibo';
import FichaEstabelecimento from '../../../gestLicenciados/fichaEstabelecimento';
import DownloadDoneOutlinedIcon from '@mui/icons-material/DownloadDoneOutlined';
// import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

export default function ExportsRecibos() {
    const [estabelecimento, setEstabelecimento] = useState('')
    const [solicitacaoRenovacao, setSolicitacaoRenovacao] = useState('')
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        api.get(`/estabelecimento/${id}`)
            .then(response => {
                // console.log(response)
                if (response.data.estabelecimento.approved === true && response.data.estabelecimento.status === 'Activo') {
                    navigate(-1)
                }
                setEstabelecimento(response.data.estabelecimento);
                setSolicitacaoRenovacao(response.data?.solicitacaoRenovacaos);
            }).catch(err => '')
    }, [id])

    return (
        <Container style={{ minHeight: '100vh' }}>
            {estabelecimento ?
                <>
                    <Typography variant="h5" style={{ marginBottom: 20 }} align="center">

                        <DownloadDoneOutlinedIcon fontSize="large" color="success" />
                        <br />
                        {estabelecimento.tipoEntrada === 'Licenciamento' && 'Solicitação de licenciamento realizado com sucesso.!'}
                        {estabelecimento.tipoEntrada === 'Renovação' && 'Solicitação de renovação realizado com sucesso.!'}
                        {/* {estabelecimento.tipoEntrada === '' && 'O Estabelecimento foi inserido com sucesso.!'} */}

                    </Typography>

                    <FichaEstabelecimento estabelecimento={estabelecimento} menu={false} />

                    {estabelecimento.tipoEntrada === 'Licenciamento' && <Recibo estabelecimento={estabelecimento} />}
                    {estabelecimento.tipoEntrada === 'Renovação' && <ReciboRenovacao renovacao={solicitacaoRenovacao} />}
                </>
                :
                <Loading text={'A carregar o recibo, aguarde!'} />
            }
        </Container>
    )
}
