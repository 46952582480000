import Compressor from "compressorjs";

const compressImage = async (file) => {
    try {
        const compressedBlob = await new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6, // Ajusta a qualidade desejada da imagem (0.0 - 1.0)
                maxWidth: 800, // Ajusta a largura máxima da imagem comprimida
                maxHeight: 800, // Ajusta a altura máxima da imagem comprimida
                mimeType: "image/jpeg", // Especifica o formato da imagem de saída
                success(compressedResult) {
                    resolve(compressedResult); // Retorna a imagem comprimida
                },
                error(error) {
                    reject(error); // Retorna o erro, caso ocorra algum problema na compressão
                },
            });
        });
        // console.log(compressedBlob)
        return compressedBlob; // Retorna a imagem comprimida para que possa ser utilizada
    } catch (error) {
        // console.error("Erro ao comprimir a imagem:", error);
        throw error; // Lança o erro novamente para que possa ser tratado pelo chamador da função
    }
};

export default compressImage;
