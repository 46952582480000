import { useEffect, useState } from 'react'
import { Avatar, Grid, MenuItem, Paper, TextField, Typography, Card, DialogActions, Button } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import apiCNP from '../../services/apiCNP'
import api from '../../services/api'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import cadastrosService from '../../services/cadastroServices'
import MessageError from '../../messages/messageError'
import LoadingBackdrop from '../../load/loadingBackdrop'
import { useNavigate } from 'react-router-dom'
import RegisterAccess from '../../utils/registerAccess'


const useStyles = makeStyles((theme) => ({
    gridItem: { padding: 5, },
}))

export default function CadastroDirectorTecnico({ usedataRepresentante, cadastro }) {
    const classes = useStyles()
    const user = useSelector(state => state.account.user);
    const dadosdirectorTecnico = useSelector(state => state.dadosdirectorTecnico.data)
    const [directorTecnico, setDirectorTecnico] = useState(dadosdirectorTecnico)
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [categoria, setCategoria] = useState([]);
    const dispatch = useDispatch()
    const [messageError, setErrorMessage] = useState('')
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    // console.log(dadosdirectorTecnico)

    useEffect(() => {
        dispatch({
            type: 'dadosdirectorTecnico',
            payload: { dadosdirectorTecnico: directorTecnico }
        })
    }, [directorTecnico, dispatch])

    useEffect(() => {
        apiCNP.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);
            })
            .catch(err => '')


        dadosdirectorTecnico?.provinciaId &&
            apiCNP.get(`/municipios-by-provincia/${dadosdirectorTecnico?.provinciaId}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                })
                .catch(err => '')

        api.get('/categoria-tecnico')
            .then(res => {
                setCategoria(res.data.categoriaTecnicos);
            })
            .catch(err => console.log(err));
    }, [dadosdirectorTecnico?.provinciaId])


    async function HandleChange(e) {

        e.target.files ?
            setDirectorTecnico((prevCadastro) => ({ ...prevCadastro, [e.target.name]: e.target.files[0] }))
            :
            setDirectorTecnico((prevCadastro) => ({ ...prevCadastro, [e.target.name]: e.target.value }))


        if (e.target.name === 'provinciaId') {
            setDirectorTecnico((prevCadastro) => ({ ...prevCadastro, municipio: '' }));
            await apiCNP.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);

                    setDirectorTecnico((prevCadastro) => ({ ...prevCadastro, provincia: res.data.provincia.designacao }));
                })
                .catch(err => '')
        }
    }

    async function createDirectorTecnico() {
        setOpen(true)

        try {
            const result = await cadastrosService.cadastroDirectorTecnico({ ...dadosdirectorTecnico, usedataRepresentante: 'nao' }, user)
            // console.log(result)
            setOpen(false)
            navigate(`/admin/get-director-tecnico/${result.data.directorTecnico._id}`)
            dispatch({
                type: 'alert',
                payload: { alert: 'Director Técnico cadastrado com sucesso.' },
            });

            return result;
        } catch (error) {

            // console.log(error)
            setOpen(false)
            setErrorMessage(error.response.data.message)
            // throw error;
        }
    }

    return (
        <>
            <RegisterAccess page={'cadastrar director técnico'} />
            {messageError && <MessageError message={messageError} />}
            <LoadingBackdrop open={open} text={'A cadastrar Director Técnico'} />

            <Card style={{ padding: 20, boxShadow: 'none' }}>

                {usedataRepresentante &&
                    <FormControl style={{ display: 'flex' }}>
                        <RadioGroup
                            row
                            style={{ alignItems: 'center', justifyContent: 'end' }}
                            defaultValue={dadosdirectorTecnico?.usedataRepresentante}
                            onClick={HandleChange}
                        >
                            <FormLabel style={{ marginRight: 10 }} >Deseja usar os mesmos dados do Representante? </FormLabel>
                            <FormControlLabel name='usedataRepresentante' value={'nao'} control={<Radio color='secondary' />} label="Não" />
                            <FormControlLabel name='usedataRepresentante' value={'sim'} control={<Radio color='secondary' />} label="Sim" />
                        </RadioGroup>
                    </FormControl>
                }

                <Typography style={{ fontSize: 20, marginBottom: 10 }}>
                    {dadosdirectorTecnico?.usedataRepresentante === 'nao'
                        ?
                        'DADOS DO DIRECTOR TÉCNICO'
                        :
                        'Insira os seguintes dados para continuar'
                    }
                </Typography>

                {dadosdirectorTecnico?.usedataRepresentante === 'nao' &&

                    <Grid container >
                        <Grid xs={12} md={2} item className={classes.gridItem}>
                            <Paper style={{ border: '1px dashed #3e3d3f' }}>
                                <label htmlFor="foto" style={{ cursor: 'pointer', textAlign: 'center' }}>
                                    {directorTecnico?.foto ?
                                        <>
                                            {/* <img src={URL.createObjectURL(directorTecnico.foto)} alt="Imagem" width="100%" title="alterar foto.." /> */}
                                            <Avatar style={{ width: '100%', height: 140 }} variant="square" src={URL.createObjectURL(directorTecnico.foto)} title="Clique para carregar uma imagem" />

                                        </>
                                        :
                                        <div style={{ padding: 7 }}>
                                            <img style={{ height: 100 }} src='/img/avatar/avatarDirectorTecnico.svg' title="Clique para carregar uma imagem" />
                                            <p> Foto</p>
                                        </div>
                                    }
                                    <input accept="image/png, image/jpg, image/jpeg" type="file" name="foto" id="foto" style={{ display: 'none' }} onChange={HandleChange} />
                                </label>
                            </Paper>
                        </Grid>

                        <Grid xs={12} md item className={classes.gridItem}>
                            <TextField
                                type="text"
                                required
                                label="Nome"
                                fullWidth
                                size="small"
                                name="nome"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={directorTecnico?.nome}
                            />

                            <TextField
                                style={{ marginTop: 10 }}
                                type="text"
                                required
                                label="Número de B.I"
                                fullWidth
                                size="small"
                                name="numeroBI"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={directorTecnico?.numeroBI}
                            />

                            <TextField
                                style={{ marginTop: 10 }}
                                type="text"
                                label="Genero"
                                select
                                fullWidth
                                size="small"
                                name="genero"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={directorTecnico?.genero ?? ''}
                            >
                                <MenuItem disabled value=''>Selecione Genero</MenuItem>
                                <MenuItem key={'M'} value={'M'} name="genero">
                                    Masculino
                                </MenuItem>
                                <MenuItem key={'F'} value={'F'} name="genero">
                                    Feminino
                                </MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                }

                <Grid container className={classes.root}>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            type="number"
                            required
                            label="Número de Carteira"
                            fullWidth
                            size="small"
                            name="numeroCarteira"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={directorTecnico?.numeroCarteira}
                        />
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Categoria"
                            select
                            fullWidth
                            size="small"
                            name="categoriaId"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={directorTecnico?.categoriaId ?? ''}
                        >
                            <MenuItem disabled value=''>Selecione Categoria</MenuItem>
                            {categoria?.map((categoria) => (
                                <MenuItem key={categoria._id} value={categoria._id}>
                                    {categoria.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid xs={12} md item className={classes.gridItem}>
                        <TextField
                            required
                            type="text"
                            label="Ordem"
                            fullWidth
                            size="small"
                            name="ordem"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={directorTecnico?.ordem}
                        />
                    </Grid>
                </Grid>

                {dadosdirectorTecnico?.usedataRepresentante === 'nao' &&
                    <>
                        <Grid container className={classes.root}>
                            <Grid xs={12} md item className={classes.gridItem}>
                                <TextField
                                    type="number"
                                    required
                                    label="Telefone1"
                                    fullWidth
                                    size="small"
                                    name="tel1"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={directorTecnico?.tel1}
                                />
                            </Grid>

                            <Grid xs={12} md item className={classes.gridItem}>
                                <TextField
                                    type="number"
                                    label="Telefone2"
                                    fullWidth
                                    size="small"
                                    name="tel2"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={directorTecnico?.tel2}
                                />
                            </Grid>
                            <Grid xs={12} md item className={classes.gridItem}>
                                <TextField
                                    type="email"
                                    required
                                    label="E-mail"
                                    fullWidth
                                    size="small"
                                    name="email"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={directorTecnico?.email}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className={classes.root}>

                            <Grid xs={12} md item className={classes.gridItem}>
                                <TextField
                                    required
                                    type="text"
                                    label="Província"
                                    select
                                    fullWidth
                                    size="small"
                                    name="provinciaId"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={directorTecnico?.provinciaId ?? ''}
                                >
                                    <MenuItem disabled value=''>Selecione Províncía</MenuItem>
                                    {provincias?.map((provincia) => (
                                        <MenuItem key={provincia._id} value={provincia._id}>
                                            {provincia.designacao}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid xs={12} md item className={classes.gridItem}>
                                {directorTecnico?.provinciaId ?
                                    <TextField
                                        required
                                        type="text"
                                        label="Município"
                                        select
                                        fullWidth
                                        size="small"
                                        name="municipio"
                                        variant="outlined"
                                        onChange={HandleChange}
                                        defaultValue={directorTecnico?.municipio ?? ''}
                                    >
                                        <MenuItem disabled value=''>Selecione Município</MenuItem>
                                        {municipios?.map((municipio) => (
                                            <MenuItem key={municipio._id} value={municipio.designacao}>
                                                {municipio.designacao}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    :
                                    <TextField
                                        type="text"
                                        disabled
                                        label="Munícipio"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                    />
                                }
                            </Grid>

                            <Grid xs={12} md item className={classes.gridItem}>
                                <TextField
                                    required
                                    type="text"
                                    label="Bairro"
                                    fullWidth
                                    size="small"
                                    name="bairro"
                                    variant="outlined"
                                    onChange={HandleChange}
                                    defaultValue={directorTecnico?.bairro}
                                />
                            </Grid>
                        </Grid>

                        <Grid xs={12} md={4} item className={classes.gridItem}>
                            <TextField
                                required
                                type="text"
                                label="Rua"
                                fullWidth
                                size="small"
                                name="rua"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={directorTecnico?.bairro}
                            />
                        </Grid>
                    </>
                }

                {/* A VARIAVEL CADASTRO INDICA DO QUE O DIRECTOR TECNICO ESTA A SER CADASTRADO DE FORMA INDIVIDUAL */}
                {cadastro &&
                    <DialogActions>
                        <Button disabled={!directorTecnico?.nome} variant="contained" style={{ background: '#85287e' }} onClick={createDirectorTecnico}>
                            Cadastrar
                        </Button>
                    </DialogActions>
                }
            </Card>
        </>
    )
}
