import React, { useState } from 'react'
// import './drawer.css'
import './upload.css'
import { TextField, MenuItem } from '@mui/material';
import FileUpload from './fileUpload';
import api from '../../../services/api';
import HeaderGestorConfigs from '../../../utils/headerGestorConfigs';
import ButtonLeft from '../../../utils/buttonLeft';
import MessageSuccess from '../../../messages/messageSuccess';
import MessageError from '../../../messages/messageError';
import { useSelector } from 'react-redux';

interface Document {
    _id: string;
    nomeFile: string;
    tipoFile: string;
    descricao: string;
    anexo: string;
}

interface UploadFormProps {
    onUploadSuccess: (newDocument: Document) => void;
}

interface RootState {
    account: {
        user: { _id: string };
    };
}
const DocUpload: React.FC<UploadFormProps> = ({ onUploadSuccess }) => {
    const user = useSelector((state: RootState) => state.account.user);

    const [messageSuccess, setMessageSuccess] = useState('');
    const [messageError, setMessageError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [fileDoc, setFileDoc] = useState({
        file: ''
    })
    const [docs, setDocs] = useState<Document>({
        _id: '',
        nomeFile: '',
        tipoFile: '',
        descricao: '',
        anexo: ''
    });


    const handleArtigoChange = (event: any) => {
        setDocs({ ...docs, [event.target.name]: event.target.value });
    };

    const handleFileInput = (file: File | null, name: string) => {
        setFileDoc((prevArquivos) => ({
            ...prevArquivos,
            [name]: file,
        }));
    };

    const handleSubmitDoc = () => {
        setMessageSuccess('');
        setMessageError('');
        setIsLoading(true);

        const formData = new FormData();

        formData.append('nomeFile', docs.nomeFile);
        formData.append('tipoFile', docs.tipoFile);
        formData.append('descricao', docs.descricao);
        formData.append('anexo', fileDoc.file);
        formData.append('userId', user?._id);

        api.post('/doc-upload/create', formData)
            .then(res => {
                onUploadSuccess(res.data.docsUpload)
                setMessageSuccess(res.data.msg)
                setDocs({
                    _id: '',
                    nomeFile: '',
                    tipoFile: '',
                    descricao: '',
                    anexo: ''
                });
                setFileDoc({ file: '' });
            })
            .catch((messageErrorr) => {
                setMessageError(messageErrorr.response.data.message);
            }).finally(() => setIsLoading(false));

    }

    return (
        <div>
            <HeaderGestorConfigs title={'Fazer upload de documento'} />
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />}

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                <TextField
                    id="nomeFile"
                    label="Nome do Documento"
                    variant="outlined"
                    name='nomeFile'
                    onChange={handleArtigoChange}
                    value={docs.nomeFile}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <TextField
                    id="tipoDoc"
                    select
                    label="Tipo de Documento"
                    defaultValue="Tipo de Documento"
                    name='tipoFile'
                    onChange={handleArtigoChange}
                    value={docs.tipoFile}
                    className='txt-form'
                    style={{ margin: '5px' }}
                >
                    <MenuItem value='Decreto'>
                        Decreto
                    </MenuItem>
                    <MenuItem value='Despacho'>
                        Despacho
                    </MenuItem>
                    <MenuItem value='Lei'>
                        Lei
                    </MenuItem>
                    <MenuItem value='Requerimento'>
                        Requerimento
                    </MenuItem>
                    <MenuItem value='Modelo'>
                        Modelo
                    </MenuItem>
                    <MenuItem value='Requisitos'>
                        Requisitos
                    </MenuItem>
                    <MenuItem value='Manual'>
                        Manual
                    </MenuItem>
                    <MenuItem value='Outros'>
                        Outros
                    </MenuItem>
                </TextField>

                <TextField id="descricao"
                    label="Descrição"
                    variant="outlined"
                    name='descricao'
                    onChange={handleArtigoChange}
                    value={docs.descricao}
                    className='txt-form'
                    style={{ margin: '5px' }}
                />

                <FileUpload onFileSelect={(file) => handleFileInput(file, 'file')} />

            </div>

            <ButtonLeft title={'Salvar'} funcao={handleSubmitDoc} children={null} disabled={isLoading} load={isLoading} />

        </div>
    )
}

export default DocUpload
