
import HeaderSession from '../../utils/headerSession';
import RegisterAccess from '../../utils/registerAccess';
import MenuControlGeral from './menuControlGeral';

export default function Dashboard() {

    return (
        <>
            <RegisterAccess page={'dashboard'} />
            <HeaderSession title={'DASHBOARD'}/>
            <MenuControlGeral />
            <iframe title="dashboard ARMED " width="100%" height="50%" src="https://app.powerbi.com/view?r=eyJrIjoiMjkwZmM0ZDUtMWQ4OC00MDY1LThiMGMtYjkzZmMxNDQ2MjRlIiwidCI6ImFlZmVhNzQ5LTkxOTQtNGJiMS1hMjBkLTkyN2EzZDk0YTU1MiJ9&embedImagePlaceholder=true&pageName=22a593cc9ce6b2d8c43f" frameborder="0" allowFullScreen="true"></iframe>
        </>
    )
}
