import React, { useState, useEffect } from 'react'
import './doc.css'
import { IoDocument } from "react-icons/io5";
import { TransitionProps } from '@mui/material/transitions';
import { Box, InputLabel, Slide, MenuItem, FormControl, Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, Container } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import api from '../../services/api';
import DocUpload from './docUploads/docUpload';
import RegisterAccess from '../../utils/registerAccess';
import HeaderSession from '../../utils/headerSession';
import HeaderGestorConfigs from '../../utils/headerGestorConfigs';
import MessageSuccess from '../../messages/messageSuccess';
import MessageError from '../../messages/messageError';
import { useSelector } from 'react-redux';
import Loading from '../../load/loading';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Documento {
    _id: string;
    tipoFile: string;
    nomeFile: string;
    descricao: string;
    anexo: string;
}

interface RootState {
    account: {
        user: {
            _id: string;
        };
    };
}

const GestDocs: React.FC = () => {
    const user = useSelector((state: RootState) => state.account.user);
    const [idFile, setIdFile] = useState('');
    const [messageSuccess, setMessageSuccess] = useState('');
    const [openConfirm, setOpenConfirm] = useState(false);
    const [tipoFile, setTipoFile] = useState('');
    const [documents, setDocumnets] = useState<Documento[]>([]);
    const [messageError, setMessageError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [getDocsRefresh, setGetDocsRefresh] = useState(false);

    const handleChange = (event: SelectChangeEvent) => {
        setTipoFile(event.target.value as string);
    };

    const handleUploadSuccess = (newDocument: Documento) => {
        setDocumnets((prevDocuments) => [...prevDocuments, newDocument]);
    };

    useEffect(() => {

        setIsLoading(true);
        setMessageSuccess('');
        setMessageError('');
        setDocumnets([]);

        api.get(`/docs-upload?tipoFile=${tipoFile}`)
            .then(res => {
                setDocumnets(res.data.docsUpload);
            })
            .catch(() => {
                setMessageError("Ocorreu algum erro ao carregar os Artigos");
            }).finally(() => {
                setIsLoading(false);
                setOpenConfirm(false);
            });
    }, [tipoFile, getDocsRefresh])

    const deleteDoc = (id: string) => {
        setMessageSuccess('');
        setMessageError('');
        setIsLoading(true);
        api.delete('/doc-upload/delete/', {
            data: {
                id: id,
                userId: user?._id
            }
        }).then((res) => {
            setGetDocsRefresh(true);
        })
            .catch((erro) => {
                setMessageError(erro.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
                setOpenConfirm(false);
            });
    }

    const editDoc = (id: string) => {
        api.put(`/doc-upload/edit/${id}`)
            .then(res => {
                // console.log(res);
            })
            .catch(() => {
                setMessageError('Ocorreu algum erro ao editar');
            });
    }

    const handleClick = (id: string) => {
        setOpenConfirm(true);
        setIdFile(id);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    return (
        <Container>
            <RegisterAccess page={'gestor de documentos'} />
            <HeaderSession title={'GESTÃO DE DOCUMENTOS'} />

            <Card style={{ padding: 20 }}>

                {/* COMPOMETE PARA FAZER UPLOADS DE DOCUMENTOS */}
                <DocUpload onUploadSuccess={handleUploadSuccess} />

                <div>

                    <HeaderGestorConfigs title={'Documentos publicados'} />

                    {messageSuccess && <MessageSuccess message={messageSuccess} />}
                    {messageError && <MessageError message={messageError} />}

                    <div style={{ marginTop: '15px', width: '100%' }}>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tipo Documento</InputLabel>
                                <Select
                                    labelId="tipo-documento"
                                    id="tipoDocumento"
                                    value={tipoFile}
                                    label="Tipo Documento"
                                    onChange={handleChange}
                                >
                                    <MenuItem value=''>
                                        Todos
                                    </MenuItem>
                                    <MenuItem value='Decreto'>
                                        Decreto
                                    </MenuItem>
                                    <MenuItem value='Despacho'>
                                        Despacho
                                    </MenuItem>
                                    <MenuItem value='Requerimento'>
                                        Requerimento
                                    </MenuItem>
                                    <MenuItem value='Modelo'>
                                        Modelo
                                    </MenuItem>
                                    <MenuItem value='Requisitos'>
                                        Requisitos
                                    </MenuItem>
                                    <MenuItem value='Manual'>
                                        Manual
                                    </MenuItem>
                                    <MenuItem value='Outros'>
                                        Outros
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>

                    <div className="pdf-articles-container">
                        {documents.map((document, index) => (
                            <div className="pdf-article-card" key={index}>
                                <div className="pdf-article-image">
                                    <IoDocument />
                                </div>
                                <div className="pdf-article-info">
                                    <p className="pdf-article-date" style={{ fontSize: '16px' }}>{document.tipoFile}</p>
                                    <small className="pdf-article-date" >{document._id}</small>
                                    {/* <h3 className="pdf-article-title">{document.nomeFile}</h3> */}
                                    <p className="pdf-article-date" style={{ fontSize: '16px', marginTop: '0' }}><span className="pdf-article-title">Descrição: </span>{document.descricao}</p>
                                    <div className="pdf-article-links">
                                        <a href={document.anexo} target="_blank" className="pdf-article-read-more">
                                            <div>Abrir</div>
                                        </a>
                                        <a href="#" className="pdf-article-download" onClick={() => editDoc}
                                            style={{ display: 'none' }}
                                        >Editar</a>
                                        <div className="pdf-article-detele" onClick={() => handleClick(document._id)} style={{ cursor: isLoading ? 'wait' : 'pointer' }}>Deletar</div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Load de processamento dos dados */}
                    {documents.length === 0 && <Loading text={''} focus={false} />}

                    <Dialog
                        open={openConfirm}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseConfirm}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"Deletar Documento"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                Deseja apagar esse documento?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseConfirm}>Não</Button>
                            <Button onClick={() => deleteDoc(idFile)}>Sim</Button>
                        </DialogActions>
                    </Dialog>

                </div>
            </Card>
        </Container>
    )
}

export default GestDocs
