
import { useParams } from "react-router-dom"
import { useState, useEffect } from 'react'
import { Card, Typography } from "@mui/material"
import { Container, Divider } from "@mui/material";
import Loading from "../../load/loading";
import api from "../../services/api";
import { useSelector } from 'react-redux';
import Date from "../../utils/date";
import ResponseParticipacoes from "./responseReclamacao";
import HeaderSession from "../../utils/headerSession";
import TextoFormatado from "../../utils/textoFormatado";


export default function ViewReclamacao() {
    let { id } = useParams()
    const [reclamacao, setReclamacao] = useState('')
    const user = useSelector(state => state.account.user);
    const [update, setUpdate] = useState(false)


    useEffect(() => {
        api.get(`/reclamacao/${id}`)
            .then(response => {
                // console.log(response)
                setReclamacao(response.data.reclamacao);
            }).catch(error => {
                // console.log(error)
                // if (error.response.status === 500) {
                //     setMessageError('Reclamação não encontrada')
                //     window.setTimeout(() => {
                //         navigate(-1)
                //     }, 1000);
                // }
            })
    }, [id, update])


    return (
        reclamacao
            ?
            <div>
                <Container style={{ marginBottom: 20 }}>
                    <HeaderSession title={'GESTÃO DE RECLAMAÇÃO'} />

                    <Card style={{ padding: 20, marginBottom: 10, position: 'relative' }}>

                        <Typography variant="body1" lineHeight={1.5}>
                            Número de reclamação: {reclamacao?.numero}
                            <br />
                            <strong>Assunto:</strong> {reclamacao?.assunto}
                            <br />
                            <strong>Nome:</strong> {reclamacao?.nome}
                            <br />
                            <strong>Contacto:</strong> {reclamacao?.tel}
                            <br />
                            <strong>Email:</strong> {reclamacao?.email}
                            <br />
                            <strong>Data:</strong> <Date date={reclamacao?.createdAt} />
                        </Typography>
                        <Divider />
                        <Typography align="center" variant="body1" style={{ margin: 10 }}>
                            Exposição
                        </Typography>
                        <TextoFormatado texto={reclamacao.descricao} />
                    </Card>

                    {reclamacao?.anexo1 &&
                        <Card style={{ padding: 20, position: 'relative' }}>
                            <Typography variant="subtitle1" style={{ margin: 10 }}>
                                Documento anexado:
                                <br />
                                <a target="_blank" href={reclamacao?.anexo1} > anexo_1</a>
                            </Typography>
                            <iframe
                                id="pdfIframe"
                                title={`PDF Viewer`}
                                src={[reclamacao?.anexo1]}
                                width="100%"
                                height="800px"
                                style={{ border: 'none' }}
                            />
                        </Card>
                    }

                    <ResponseParticipacoes
                        userId={user?._id}
                        id={id}
                        respostas={reclamacao.respostas}
                        setUpdate={setUpdate}
                    />

                </Container>
            </div>
            :
            <Loading text={'A acarregar reclamção.'} />

    )
}