
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        width: '15%',
        minWidth: 200,
        height: 150,
        // lineHeight: 1,
        // fontSize: 16, 

        border: '1px solid #85287e',
        borderRadius: 10,
        transition: '.5s',
        '&:hover': { background: '#85287d8a', transform: 'scale(1.1)',  border: '1px solid #000',},

        [theme.breakpoints.down('xs')]: {
            minWidth: 135,
            width: '40%',
            height: 180,
        },
    },
}))

type cardServicesProps = {
    icone?: string;
    nomeService: string;
    link: string;
    status: boolean;
}

export default function CardServices({ icone, nomeService, link, status }: cardServicesProps) {
    const classes = useStyles()

    return (
        <>
            <CardActionArea disabled={!status} className={classes.root} >
                <Link style={{ textDecoration: 'none' }} to={link}>
                    <CardContent>
                        <div style={{ height: 50 }}>
                            <img src={icone} alt={''} style={{ height: 75 }} />
                        </div>
                    </CardContent>

                    <CardContent >
                        <Typography color="textPrimary" variant="body1">
                            {nomeService}
                        </Typography>
                    </CardContent>
                </Link>

                {/* {manual &&
                    <ButtonBase  style={{ background: '#85287e', fontSize: 10 }} >
                        <a style={{ textDecoration: 'none', color: '#fff' }} href="manual-instructivo-licenciamento" target="_blank">Baixar Manual</a>
                    </ButtonBase>
                } */}
            </CardActionArea>
        </>
    )
}