import NavBar from '../components/navBar'
import Footer from '../components/footer'

export default function TemplateHome({ element: component, footer = true }) {
    return (
        <div style={{ marginTop: 100 }}>
            <NavBar />
            {component}

            {footer && <Footer />}

        </div>
    )
}
