import React, { useState } from 'react';
import { BsUpload } from "react-icons/bs";

interface FileUploaderProps {
    onFileSelect: (file: File | null) => void;
    maxSizeInMB?: number;
}

const FileUpload: React.FC<FileUploaderProps> = ({ onFileSelect, maxSizeInMB = 10 }) => {
    const [dragActive, setDragActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);

    const allowedTypes = [
        'application/pdf',             // PDF
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
        'application/vnd.ms-excel',    // XLS
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
        'application/zip',             // ZIP
        'application/x-rar-compressed', // RAR

        'image/jpeg',
        'image/png',
        'image/jpg',
    ];

    const validateFile = (file: File): boolean => {
        if (!allowedTypes.includes(file.type)) {
            setError('Tipo de arquivo não suportado. Por favor, selecione documentos.');
            return false;
        }

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > maxSizeInMB) {
            setError(`O arquivo excede o tamanho máximo de ${maxSizeInMB}MB.`);
            return false;
        }

        setError(null);
        return true;
    };

    const handleDragOver = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const file = e.dataTransfer.files[0];
            if (validateFile(file)) {
                setSelectedFile(file);
                onFileSelect(file);
            } else {
                onFileSelect(null);
            }
            e.dataTransfer.clearData();
        }
    };

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (validateFile(file)) {
                setSelectedFile(file);
                onFileSelect(file);
            } else {
                e.target.value = '';
                onFileSelect(null);
            }
        }
    };
    return (
        // <div className="txt-form-dra">
        <form
            className="file-upload-form"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <input
                id="file-upload-input"
                type="file"
                onChange={handleFileSelect}
                className="file-upload-input"
                accept=".pdf, image/png, image/jpg, image/jpeg"
            />
            <label
                htmlFor="file-upload-input"
                className={`file-upload-label ${dragActive ? 'drag-active' : ''}`}
            >
                <div className="upload-icon-container">
                    <BsUpload className="upload-icon" style={{ display: selectedFile ? 'none' : '' }} />
                    <p className="upload-text" style={{ display: selectedFile ? 'none' : '' }}>
                        Arraste e solte um documento aqui ou clique para carregar (máx. {maxSizeInMB}MB)
                        {error && <p className="error-message">{error}</p>}
                    </p>
                    <p className="upload-text txt-name">{selectedFile?.name}</p>
                </div>
            </label>
        </form>
        // </div>
    )
}

export default FileUpload
