
import { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import apiCNP from "../../services/apiCNP";
import { Grid, MenuItem, Paper, TextField, Card } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    gridItem: { padding: 5 },
}))


export default function FormCadastroEmpresa() {
    const classes = useStyles()
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const dadosEmpresa = useSelector(state => state.dadosEmpresa.data)
    const [empresa, setEmpresa] = useState(dadosEmpresa);
    const dispatch = useDispatch()
    // console.log(dadosEmpresa)

    useEffect(() => {
        dispatch({
            type: 'dadosEmpresa',
            payload: { dadosEmpresa: empresa }
        })
    }, [empresa, dispatch])


    useEffect(() => {
        apiCNP.get('/provincias')
            .then(res => {
                setProvincias(res.data.provincias);
            }).catch(err => '')
    }, [dadosEmpresa?.provinciaId])


    async function HandleChange(e) {

        e.target.files
            ?
            setEmpresa((prevCadastro) => ({ ...prevCadastro, [e.target.name]: e.target.files[0] }))
            :
            setEmpresa((prevCadastro) => ({ ...prevCadastro, [e.target.name]: e.target.value }))


        if (e.target.name === 'provinciaId') {
            setEmpresa((prevCadastro) => ({ ...prevCadastro, municipio: '' }));

            await apiCNP.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);

                    setEmpresa((prevCadastro) => ({ ...prevCadastro, provincia: res.data.provincia.designacao }));
                }).catch(err => console.log(err))
        }
    }

    return (
        <Card style={{ padding: 20, boxShadow: 'none' }}>

            <Typography style={{ fontSize: 20, margin: 10 }}>
                DADOS DA EMPRESA
            </Typography>

            <Grid container>
                <Grid xs={12} md={2} item className={classes.gridItem} >
                    <Paper style={{ border: '1px dashed #3e3d3f' }}>
                        <label htmlFor="logo" style={{ cursor: 'pointer', textAlign: 'center' }}>
                            {dadosEmpresa?.logo ?
                                <>
                                    {/* <img src={URL.createObjectURL(empresa.logo)} alt="Imagem" width="100%" title="alterar logo.." /> */}
                                    <img style={{ width: '100%', height: 140 }} src={URL.createObjectURL(dadosEmpresa.logo)} title="Clique para carregar uma imagem" />

                                </>
                                :
                                <div style={{ padding: 7 }}>
                                    <img style={{ height: 100 }} src='/img/avatar/avatarEmpresa.svg' title="Clique para carregar uma imagem" />
                                    <p>
                                        Logo
                                    </p>
                                </div>
                            }
                            <input accept="image/png, image/jpg, image/jpeg" type="file" name="logo" id="logo" style={{ display: 'none' }} onChange={HandleChange} />
                        </label>
                    </Paper>
                </Grid>

                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="text"
                        required
                        label="Nome Empresa"
                        fullWidth
                        size="small"
                        name="nome"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={dadosEmpresa?.nome}
                    />

                    <TextField
                        // margin='normal'
                        style={{ marginTop: 10 }}
                        type="text"
                        required
                        label="NIF"
                        fullWidth
                        size="small"
                        name="nif"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={dadosEmpresa?.nif}
                    />
                    <TextField
                        style={{ marginTop: 10 }}
                        type="email"
                        required
                        label="E-mail"
                        fullWidth
                        size="small"
                        name="email"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={dadosEmpresa?.email}
                    />
                </Grid>
            </Grid>

            <Grid container className={classes.root}>

                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="number"
                        required
                        label="Telefone1"
                        fullWidth
                        size="small"
                        name="tel1"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={dadosEmpresa?.tel1}
                    />
                </Grid>
                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        type="number"
                        label="Telefone2"
                        fullWidth
                        size="small"
                        name="tel2"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={dadosEmpresa?.tel2}
                    />
                </Grid>
                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        required
                        type="text"
                        label="Endereço"
                        fullWidth
                        size="small"
                        name="rua"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={dadosEmpresa?.rua}
                    />
                </Grid>
            </Grid>

            <Grid container className={classes.root}>

                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        required
                        type="text"
                        label="Província"
                        select
                        fullWidth
                        size="small"
                        name="provinciaId"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={dadosEmpresa?.provinciaId ?? ''}
                    >
                        <MenuItem disabled value=''>Selecione Províncía</MenuItem>
                        {provincias.map((provincia) => (
                            <MenuItem key={provincia._id} value={provincia._id}>
                                {provincia.designacao}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid xs={12} md item className={classes.gridItem}>
                    {dadosEmpresa?.provinciaId ?
                        <TextField
                            required
                            type="text"
                            label="Município"
                            select
                            fullWidth
                            size="small"
                            name="municipio"
                            variant="outlined"
                            onChange={HandleChange}
                            defaultValue={dadosEmpresa?.municipio ?? ''}
                        >
                            <MenuItem disabled value=''>Selecione Município</MenuItem>
                            {municipios?.map((municipio) => (
                                <MenuItem key={municipio._id} value={municipio.designacao}>
                                    {municipio?.designacao}
                                </MenuItem>
                            ))}
                        </TextField>
                        :
                        <TextField
                            type="text"
                            disabled
                            label="Município"
                            fullWidth
                            size="small"
                            variant="outlined"
                        />
                    }
                </Grid>

                <Grid xs={12} md item className={classes.gridItem}>
                    <TextField
                        required
                        type="text"
                        label="Bairro"
                        fullWidth
                        size="small"
                        name="bairro"
                        variant="outlined"
                        onChange={HandleChange}
                        defaultValue={dadosEmpresa?.bairro}
                    />
                </Grid>
            </Grid>
        </Card >
    )
}