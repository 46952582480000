
import { useEffect, useState } from "react";
import HeaderSession from "../../../../../utils/headerSession";
import { Button, Card, Container, DialogActions } from "@mui/material";
import { useParams } from "react-router-dom";
import Loading from "../../../../../load/loading";
import api from "../../../../../services/api";
import RegisterAccess from "../../../../../utils/registerAccess";
import FichaEstabelecimento from "../../../../gestLicenciados/fichaEstabelecimento";
import ItensFactura from "./itensFactura";
import DocumentViewer from "../documentViewer";
import FichaSolicitacaoImportacao from "../fichaSolicitacaoImportacao";


export default function ConsultaSolicitacaoImportacao({ element: component, ...rest }) {
    const { id } = useParams()
    const [solicitacaoImportacao, setSolicitacao] = useState('')

    useEffect(() => {
        api.get(`/solicitacao-importacao/${id}`)
            .then(response => {
                // console.log(response)
                setSolicitacao(response.data.solicitacaoImportacao)
            }).catch(err => 'console.log(err)')
    }, [id])

    return (
        <Container style={{ maxWidth: 800 }}>
            <RegisterAccess page={'gestão de solicitacao de importação'} />
            <HeaderSession title={'ACOMPANHAMENTO DE SOLICITAÇĀO DE IMPORTAÇÃO'} />

            {solicitacaoImportacao ?
                <div>
                    {/* Action para solicitar desalfandegamento */}
                    <DialogActions>
                        <Button>Desalfandegar</Button>
                    </DialogActions>

                    <FichaSolicitacaoImportacao solicitacaoImportacao={solicitacaoImportacao} />

                    <br />
                    <FichaEstabelecimento title={'DADOS DO IMPORTADOR'} estabelecimento={solicitacaoImportacao?.importador} menu={false} />

                    <br />
                    <Card style={{ padding: 20 }}>
                        <DocumentViewer pdfUrls={solicitacaoImportacao?.docs} />
                    </Card>

                    <br />
                    <Card style={{ padding: 20 }}>
                        <ItensFactura itens={solicitacaoImportacao?.items} solicitacao={solicitacaoImportacao} utente={true} />
                    </Card>

                </div>
                :
                <Loading />
            }
        </Container>
    )
}