
import { Container } from '@mui/material'
import HeaderSession from '../../../../../utils/headerSession';
import CadastroEstabelecimento from '../../licenciamento/cadastroEstabelecimento';


export default function Index() {

    return (
        <Container >
            <HeaderSession title='RENOVAÇÃO DE ESTABELECIMENTO' />

            {/* <Alert severity="warning"> <b>OBS: </b> Antes de inserir qualquer informação nos formulários use o recurso de  <b>Pesquisar dados</b>.</Alert> */}
           
            <CadastroEstabelecimento tipoRegisto={'Inserção'} />

            <br />
        </Container>
    )
}

