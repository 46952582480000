
import { useEffect, useState } from "react";
import HeaderSession from "../../../../utils/headerSession";
import { Button, Card, Container, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Loading from "../../../../load/loading";
import api from "../../../../services/api";
import RegisterAccess from "../../../../utils/registerAccess";
import ItensFactura from "./utente/itensFactura";
import DocumentViewer from "./documentViewer";
import FichaSolicitacaoImportacao from "./fichaSolicitacaoImportacao";
import ButtonLeft from "../../../../utils/buttonLeft";
import FazerObservacoes from "../../dlif/fazerObservacoes.";
import * as yup from 'yup'; // VALIDACAO
import MessageSuccess from "../../../../messages/messageSuccess";
import MessageError from "../../../../messages/messageError";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import NavLateral from "../../../gestLicenciados/navLateral";
import LoadingBackdrop from "../../../../load/loadingBackdrop";
import DocumentosEmitidos from "./docsEmitidos/documentosEmitidos";


const useStyles = makeStyles((theme) => ({
    grid1: {
        width: 300,
        marginBottom: 15,
        marginLeft: 'auto',
        marginRight: 'auto',

        [theme.breakpoints.up('md')]: {
            // width: '100%',
            height: '100%',
            position: 'sticky',
            top: 70,
        },
    },
    grid2: {
        flexGrow: 1,
        marginLeft: 15,
        // minHeight: '100vh',
        width: 500,
        zIndex: 1,
        // position: 'relative',
    }
}))

export default function GestSolicitacaoImportacaoExportacao({ element: component, ...rest }) {
    const { id } = useParams()
    const classes = useStyles()
    const user = useSelector(state => state.account.user);
    const [solicitacaoImportacao, setSolicitacao] = useState('')
    const [estabelecimento, setEstabelecimento] = useState('')
    const [action, setAction] = useState('approve')
    const [obs, setObs] = useState('')
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [load, setLoad] = useState(false)
    const [refresh, setRefresh] = useState(null)

    useEffect(() => {
        setEstabelecimento(null);

        api.get(`/solicitacao-importacao/${id}`)
            .then(response => {
                // console.log(response);
                setSolicitacao(response.data.solicitacaoImportacao);
                setEstabelecimento(response.data.solicitacaoImportacao.importador);
            }).catch(err => 'console.log(err)')
    }, [id, refresh])

    const handleApprove = (assunto) => {
        setLoad(true)
        api.patch('/solicitacao-importacao/approve', {
            solicitacaoImportacaoId: id,
            userId: user?._id
        }).then(response => {
            // console.log(response);
            HandleCreateobservacao(assunto);
            setRefresh(response.data);
        }).catch(err => {
            // console.log(err);
        }).finally(() => setLoad(false))
    }

    const handleChangeStatus = () => {
        setLoad(true)
        api.patch('/solicitacao-importacao/change-status', {
            // status: status,
            status: 'Reprovado',
            solicitacaoImportacaoId: id,
            userId: user?._id
        }).then(response => {
            // console.log(response)
        }).catch(err => {
            // console.log(err)
        }).finally(() => setLoad(false))
    }

    async function HandleCreateobservacao(assunto) {
        setLoad(true)
        setMessageError('')
        setMessageSuccess('')

        await api.post('/observacao/create', {
            assunto: assunto,
            descricao: obs,
            userId: user._id,
            estabelecimentoId: estabelecimento._id
        }).then(res => {
            setLoad(false)
            setMessageSuccess(res.data.msg)

        }).catch(error => {
            setMessageError(error.response.data.message)
            setLoad(false)
        })
    }

    async function validate() {
        setMessageError('');
        setAction('reprove')

        let schema = yup.object().shape({
            observacao: yup.string("Necessário inserir as observações").required("Necessário inserir observações"),
        })

        try {
            await schema.validate({ 'observacao': obs })
            handleChangeStatus()
        } catch (error) {
            setMessageError(error.errors);
        }
        return false
    }

    return (
        <Container>
            <RegisterAccess page={'gestão de solicitacao de importação'} />
            <HeaderSession title={'GESTÃO DE SOLICITAÇĀO DE IMPORTAÇÃO'} />
            <LoadingBackdrop open={load} text={'Estás aprovar uma solicitacao de importação, aguarde.! '} />

            {estabelecimento ?
                <Grid container>
                    <Grid item className={classes.grid1} m>
                        <NavLateral estabelecimento={solicitacaoImportacao?.importador} thisId={solicitacaoImportacao?.importador._id} />
                    </Grid>

                    <Grid xs item className={classes.grid2} m>

                        <FichaSolicitacaoImportacao solicitacaoImportacao={solicitacaoImportacao} />

                        <br />
                        <Card style={{ padding: 20 }}>
                            <DocumentViewer pdfUrls={solicitacaoImportacao?.docs} />
                        </Card>

                        <br />
                        <Card style={{ padding: 20 }}>
                            <ItensFactura itens={solicitacaoImportacao?.items} />
                        </Card>

                        <br />
                        {(solicitacaoImportacao?.status === 'Aprovado' && solicitacaoImportacao?.dataAprovacao) ?
                            <DocumentosEmitidos solicitacaoImportacao={solicitacaoImportacao} />
                            :
                            <Card style={{ padding: 20 }}>
                                {messageSuccess && <MessageSuccess message={messageSuccess} />}
                                {messageError && <MessageError message={messageError} />}

                                <FazerObservacoes setObs={setObs} obs={obs} titulo={action === 'approve' ? '(caso seja necessário fazer alguma observação)' : '(Descreve os motivos desta reprovação para o utente)'} />
                                <ButtonLeft title={'Aprovar'} funcao={() => handleApprove('Aprovado')} disabled={false} >
                                    <Button color="error" onClick={validate}>Reprovar</Button>
                                </ButtonLeft>
                            </Card>
                        }

                    </Grid>
                </Grid>
                :
                <Loading />
            }
        </Container>

    )
}