import { Instagram, LinkedIn } from "@material-ui/icons";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { makeStyles } from "@material-ui/core";
// import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

const useStyles = makeStyles({
    menuItens: {
        marginRight: 8,
        marginLeft: 8,
        textDecoration: 'none',
        // color: '#fff',
        color: '#85287e',
        transition: '.5s',
        '&:hover': {
            color: '#eaa925',
        },
    },
})

export default function RedesSocias() {
    const classes = useStyles()

    return (
        <nav>
            <a href={'https://www.facebook.com/ARMED.Angola/'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                <FacebookRoundedIcon />
            </a>

            <a href={'https://www.instagram.com/armed_angola/'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                <Instagram />
            </a>

            <a href={'https://www.linkedin.com/company/96306438/admin/feed/posts/'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                <LinkedIn />
            </a>

            {/* <a href={'/'} target={'_blank'} rel="noreferrer" className={classes.menuItens} >
                <YouTube />
            </a> */}
        </nav>
    )
}