

import ExcelJS from 'exceljs';
// import * as XLSX from 'xlsx';

/** ESTABELECIMENTOS LICENCIADOS **/
const EstabelecimentosLicenciadosexcel = async (dados) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Minha Tabela');
    worksheet.columns = [
        { header: 'Nome', key: 'nome', width: 80 },
        { header: 'Tipo', key: 'tipo', width: 30 },
        { header: 'Nº de Processo', key: 'nprocesso', width: 30 },
        { header: 'Director Técnico', key: 'director', width: 50 },
        { header: 'Provincia', key: 'provincia', width: 30 },
        { header: 'estado', key: 'estado', width: 30 },
    ];
    worksheet.getRow(1).font = {
        size: 18,
        bold: true,
        color: { argb: 'fafafa' },
    };
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '713073' }
    };
    dados.slice(0, 50).forEach((estabelecimento) => {
        worksheet.addRow({
            nome: estabelecimento?.nome,
            tipo: estabelecimento?.tipo?.actividade,
            nprocesso: estabelecimento?.numeroProcesso,
            director: estabelecimento?.directorTecnico?.dadosPessoais?.nome,
            provincia: estabelecimento?.provincia,
            estado: estabelecimento?.status,
        });
    });
    for (let i = 2; i <= dados.length + 1; i++) {
        worksheet.getRow(i).font = { size: 14 };
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Estabelecimentos.xlsx';
    link.click();
};

/** SOLICITAÇ��ES DE LICENCIAMENTO **/
const SolicitacoesLicenciamentoExcel = async (dados) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Minha Tabela');
    worksheet.columns = [
        { header: 'Nome', key: 'nome', width: 80 },
        { header: 'Tipo', key: 'tipo', width: 30 },
        { header: 'Nº de Entrada', key: 'nprocesso', width: 30 },
        { header: 'Director Tecnico', key: 'director', width: 30 },
        { header: 'Solicitação', key: 'solicitacao', width: 30 },
        { header: 'Status', key: 'status', width: 30 },
        { header: 'Data de Aprovação', key: 'dataAprovacao', width: 30 },
        { header: 'Data de Expiração', key: 'dataExpiracao', width: 30 },
    ]; worksheet.getRow(1).font = { size: 18, bold: true,  color: { argb: 'fafafa' }, };
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '713073' }
    };
    dados.slice(0, 50).forEach((solicitacao) => {
        worksheet.addRow({
            nome: solicitacao?.nome,
            tipo: solicitacao?.tipo?.designacao,
            nprocesso: solicitacao?.numeroEntrada,
            director: solicitacao?.directorTecnico?.dadosPessoais?.nome,
            solicitacao: solicitacao?.tipoEntrada,
            status: solicitacao?.status,
            dataAprovacao: solicitacao?.createdAt,
            dataExpiracao: solicitacao?.updatedAt,
        });
    });
    for (let i = 2; i <= dados.length + 1; i++) {
        worksheet.getRow(i).font = { size: 14, };
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Solicitacoes.xlsx';
    link.click();
};

/** RELATORIO EMPRESAS **/
const RelatorioEmpresasExcel = async (dados) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Minha Tabela');
    worksheet.columns = [
        { header: 'Nome', key: 'nome', width: 80 },
        { header: 'Provincia', key: 'provincia', width: 30 },
        { header: 'Municipio', key: 'estado', width: 30 },
    ]; worksheet.getRow(1).font = { size: 18, bold: true,  color: { argb: 'fafafa' }, };
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '713073' }
    };
    dados.slice(0, 50).forEach((empresa) => {
        worksheet.addRow({
            nome: empresa?.nome,
            provincia: empresa?.provincia,
            estado: empresa?.municipio,
        });
    });
    for (let i = 2; i <= dados.length + 1; i++) {
        worksheet.getRow(i).font = { size: 14, };
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'RelatorioEmpresas.xlsx';
    link.click();
};

/** RELATORIO REPRESENTANTES **/
const RelatorioRepresentantesExcel = async (dados) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Minha Tabela');
    worksheet.columns = [
        { header: 'Nome', key: 'nome', width: 80 },
        { header: 'Email', key: 'tipo', width: 30 },
        { header: 'Telefone', key: 'nprocesso', width: 30 },
        { header: 'Provincia', key: 'provincia', width: 30 },
        { header: 'Municipio', key: 'estado', width: 30 },
    ]; worksheet.getRow(1).font = { size: 18, bold: true,  color: { argb: 'fafafa' },};
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '713073' }
    };
    dados.slice(0, 50).forEach((representante) => {
        worksheet.addRow({
            nome: representante?.dadosPessoais?.nome,
            tipo: representante?.dadosPessoais?.email,
            nprocesso: representante?.dadosPessoais?.tel1,
            provincia: representante?.dadosPessoais?.provincia,
            estado: representante?.dadosPessoais?.municipio,
        });
    });
    for (let i = 2; i <= dados.length + 1; i++) {
        worksheet.getRow(i).font = { size: 14, };
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'RelatorioRepresentantes.xlsx';
    link.click();
};

/** RELATORIO DE DIRECTORES TECNICOS **/
const RelatorioDirectoresTecnicosExcel = async (dados) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Minha Tabela');
    worksheet.columns = [
        { header: 'Nome', key: 'nome', width: 80 },
        { header: 'Carteira', key: 'carteira', width: 30 },
        { header: 'Categoria', key: 'categoria', width: 30 },
        { header: 'Telefone', key: 'telefone', width: 30 },
        { header: 'Provincia', key: 'provincia', width: 30 },
        { header: 'Municipio', key: 'municipio', width: 30 },
    ]; worksheet.getRow(1).font = { size: 18, bold: true, color: { argb: 'fafafa' }, };
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '713073' }
    };
    dados.slice(0, 50).forEach((director) => {
        worksheet.addRow({
            nome: director?.dadosPessoais?.nome,
            carteira: director?.numeroCarteira,
            categoria: director?.categoria?.designacao,
            telefone: director?.dadosPessoais?.tel1,
            provincia: director?.dadosPessoais?.provincia,
            municipio: director?.dadosPessoais?.municipio,
        });
    });
    for (let i = 2; i <= dados.length + 1; i++) {
        worksheet.getRow(i).font = { size: 14, };
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'RelatorioDirectores.xlsx';
    link.click();
};

/** RELATORIO GESTAO DE AGENDA **/
const RelatorioAgendaExcel = async (dados) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Minha Tabela');
    worksheet.columns = [
        { header: 'Tipo', key: 'tipo', width: 50 },
        { header: 'Hora', key: 'hora', width: 30 },
        { header: 'Dia', key: 'dia', width: 30 },
        { header: 'Mes', key: 'mes', width: 30 },
        { header: 'Ano', key: 'ano', width: 30 },
        { header: 'Status', key: 'status', width: 30 },
        { header: 'Data para disponiblizar', key: 'disponiblizar', width: 30 },
    ]; worksheet.getRow(1).font = { size: 18, bold: true, color: { argb: 'fafafa' }, };
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '713073' }
    };
    dados.slice(0, 50).forEach((agenda) => {
        worksheet.addRow({
            tipo: agenda?.tipo,
            hora: agenda?.hora,
            dia: agenda?.dia,
            mes: agenda?.mes,
            ano: agenda?.ano,
            status: agenda?.status,
            disponiblizar: agenda?.dataDisponivel.slice(0, 10).split('-').reverse().join('/'),
        });
    });
    for (let i = 2; i <= dados.length + 1; i++) {
        worksheet.getRow(i).font = { size: 14, };
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'RelatorioAgenda.xlsx';
    link.click();
};

/** RELATORIO ADIMINISTRADORES DO SISTEMA **/
const RelatorioAdministadoresExcel = async (dados) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Minha Tabela');
    worksheet.columns = [
        { header: 'Nome', key: 'nome', width: 80 },
        { header: 'Departamento', key: 'departamento', width: 30 },
        { header: 'Seccao', key: 'seccao', width: 30 },
        { header: 'Agente', key: 'agente', width: 30 },
        { header: 'Funcao', key: 'funcao', width: 30 },
        { header: 'BI', key: 'bi', width: 30 },
        { header: 'Email', key: 'email', width: 30 },
        { header: 'Telefone1', key: 'telefone1', width: 30 },
        { header: 'Telefone2', key: 'telefone2', width: 30 },
        { header: 'Rua', key: 'rua', width: 30 },
        { header: 'Bairro', key: 'bairro', width: 30 },
        { header: 'Distrito', key: 'distrito', width: 30 },
        { header: 'Provincia', key: 'provincia', width: 30 },
    ]; worksheet.getRow(1).font = { size: 18, bold: true,  color: { argb: 'fafafa' }};
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '713073' }
    };
    dados.slice(0, 50).forEach((director) => {
        worksheet.addRow({
            nome: director?.dadosPessoais?.nome,
            departamento: director?.departamento,
            seccao: director?.seccao,
            agente: director?.numeroAgente,
            funcao: director?.funcao,
            bi: director?.dadosPessoais?.numeroBI,
            email: director?.dadosPessoais?.email,
            telefone1: director?.dadosPessoais?.tel1,
            telefone2: director?.adosPessoais?.tel2,
            rua: director?.adosPessoais?.rua,
            bairro: director?.dadosPessoais?.bairro,
            distrito: director?.dadosPessoais?.municipio,
            provincia: director?.dadosPessoais?.provincia,
        });
    });
    for (let i = 2; i <= dados.length + 1; i++) {
        worksheet.getRow(i).font = { size: 14, };
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'RelatorioDirectores.xlsx';
    link.click();
};

export {
     RelatorioAdministadoresExcel,
     EstabelecimentosLicenciadosexcel,
     RelatorioRepresentantesExcel,
     RelatorioEmpresasExcel,
     SolicitacoesLicenciamentoExcel,
     RelatorioDirectoresTecnicosExcel,
     RelatorioAgendaExcel
    };
