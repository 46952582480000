import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Box, DialogActions, IconButton, Menu, MenuItem, ListItemIcon, Tooltip } from "@mui/material";
import { Download } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";

export default function HeaderSession(props) {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <h1 style={{ margin: 20, fontSize: 23, display: 'flex', alignItems: 'center', flexGrow: 1, }}>
                    <IconButton onClick={() => navigate(-1)} style={{ marginRight: 15, }} title='Voltar'>
                        <img width={20} src="/img/icones/setaback1.svg" alt="" />
                    </IconButton>
                    <span translate="no">
                        {props.title}
                    </span>
                </h1>

                {props.actions &&
                    <DialogActions >

                        <Tooltip title="Opções para Baixar">
                            <IconButton
                                title="Baixar"
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                            >
                                <Download />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            <MenuItem onClick={props.handleExportExcel}>
                                <ListItemIcon>
                                    <DownloadIcon color="secondary" />
                                </ListItemIcon>
                                Baixar Excel
                            </MenuItem>
                            <MenuItem onClick={props.handleExportPDF}>
                                <ListItemIcon>
                                    <DownloadIcon color="secondary" />
                                </ListItemIcon>
                                Baixar PDF
                            </MenuItem>
                        </Menu>

                    </DialogActions>
                }

            </Box>

            <hr style={{ border: '1px solid #c43ab9' }} />
            <br />
        </>

    )
}
