import { Button,TextField, Typography } from '@mui/material'
import { useState } from 'react'
import api from '../../services/api'
import { useSelector } from 'react-redux';
import MessageSuccess from '../../messages/messageSuccess';
import MessageError from '../../messages/messageError';
import LoadingBackdrop from '../../load/loadingBackdrop';
import RegisterAccess from '../../utils/registerAccess';

export default function EditeSenha({ user, cancelEdite }) {
    const admin = useSelector(state => state.account.user);
    const [edite, setEdite] = useState(false)
    const [load, setLoad] = useState(false)
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')

    function HandleChange(e) { setEdite({ ...edite, [e.target.name]: e.target.value }) }

    async function editPassword() {
        setMessageSuccess('')
        setMessageError('')
        setLoad(true)

        api.patch('/auth/edit-password', { ...edite, "userId": user._id, 'adminId': admin._id ?? '' })
            .then(response => {
                // console.log(response)
                setLoad(false)
                setMessageSuccess(response.data.msg)
                
            }).catch(err => {
                // console.log(err)
                setLoad(false)
                setMessageError(err.response.data.message)
            })
    }

    return (
        <>
           <RegisterAccess page={'editar senha admin'}/>
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />}
            <LoadingBackdrop open={load} text={'A salvar senha'}/>

            <Typography style={{ margin: 20, fontSize: 20, fontWeight: 'bold' }} align="center" variant="h1">
                Alterar senha
            </Typography>

            <div style={{ maxWidth: 300, display: 'flex', flexDirection: 'column', gap: '1rem', margin: 'auto' }}>
                <TextField
                    type="password"
                    label="Senha Actual"
                    fullWidth
                    size="small"
                    name="oldPassword"
                    variant="outlined"
                    onChange={HandleChange}
                />

                <TextField
                    type="password"
                    label="Nova senha "
                    fullWidth
                    size="small"
                    name="newPassword"
                    variant="outlined"
                    onChange={HandleChange}
                />

                <Button onClick={editPassword} fullWidth color="inherit" style={{ background: '#85287e' }}>Salvar</Button>
                <Button onClick={() => cancelEdite(false)} fullWidth color="error" >cancelar</Button>
            </div >
        </>
    )
}
