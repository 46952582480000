
import { Container } from "@material-ui/core";


export default function GestEmpresas({ element: component, ...rest }) {

    return (
            <Container>
                {component}
            </Container>
    )
}